import React, { Component, createRef } from "react";
import "./dialog.css";
import {
  fetchMethod,
  DownloadFile,
  deleteUserDocs,
  updateUserDocsInfo,
  modifyingDocs,
  getAllDoctTypes,
  addDocType,
  getData,
  uploadUserDocFiles,
  mutationQueries,
  fetchFile,
} from "./DataQueries";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Autocomplete from "@mui/material/Autocomplete";
import swal from "sweetalert";
import Dropzone from "react-dropzone";
import downloadjs from "downloadjs";
import URIDATA from "../config";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DownloadIcon from "@mui/icons-material/Download";

export class UserDocumentLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      visible: "none",
      labelSelected: null,
      allDocTypes: [],
      file: "",
      docName: "",
      isEdit: this.props.isEdit,
      allUserDocs: [],
      show: false,
      filePath: "",
      fileName: "",
      updateOrinsert: null,
      temp: "",
      docId: "",
      docType: "",
      otherTextField: "",
      visibleDocType: "none",
      resumeorDocs: this.props.resumeorDoc,
      showDoctypeButton: this.props.showDoctypeButton,
      disable: "auto",
      docTypeName: "",
      isChecked: false,
      showFileViewer: false,
      currentFileType: "",
      currentFilePath: "",
      formerror: [],
      files: [],
      submitError: "",
    };
    this.promptbox = createRef(null);
    this.updateFields = this.updateFields.bind(this);
    this.manageDocType = this.manageDocType.bind(this);
    this.doctypeHandler = this.doctypeHandler.bind(this);
    this.save = this.save.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
    this.deleteDoc = this.deleteDoc.bind(this);
    this.downladtheFile = this.downladtheFile.bind(this);
    this.$decideDownloadOrView = this.$decideDownloadOrView.bind(this);
    this.showAddDocTypeButton = this.showAddDocTypeButton.bind(this);
    this.insertDoctype = this.insertDoctype.bind(this);
    this.onClickCheckBox = this.onClickCheckBox.bind(this);
    this.setQueryVar = this.setQueryVar.bind(this);
    this.checkOp = this.checkOp.bind(this);
    this.validateFile = this.validateFile.bind(this);
  }
  onClickCheckBox() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  renderFile = (file, type) => {
    switch (type) {
      case "img":
        const image = new Image();
        image.src = "data:image;base64," + file;
        const w = window.open("");
        w.document.write(image.outerHTML);
        break;

      case "pdf":
        let pdfWindow = window.open("");
        pdfWindow.document.write(
          "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
          encodeURI(file) +
          "'></iframe>"
        );
        break;

      default:
        swal("you can only view pdf and images");
    }
  };

  viewEncryptFile = (queryName, fileparam) => {
    let queryFill = queryName
      .substring(queryName.indexOf("{") + 1)
      .split("(")[0]
      .trim();
    fetchMethod(queryName, fileparam)
      .then((res) => res.json())
      .then((res) => {
        if (res.errors) throw new Error(res.errors[0].message);
        else {
          const fileType = res.data[`${queryFill}`].fileName.split(".")[1];
          const file = res.data[`${queryFill}`].fileData;
          const imgType = [
            "gif",
            "GIF",
            "jpeg",
            "JPEG",
            "png",
            "PNG",
            "jpg",
            "JPG",
          ];

          if (imgType.includes(fileType)) {
            this.renderFile(file, "img");
          } else {
            this.renderFile(file, fileType);
          }
        }
      })
      .catch((err) => {
        this.setState({
          show: false,
        });
        swal(err.message);
      });
  };

  updateFields() {
    let allTypes = this?.state?.allUserDocs?.map((item) =>
      item.docType.toLowerCase()
    );
    let currType = this?.state?.allDocTypes?.filter((item) =>
      item.id === this?.state?.labelSelected ? item.docType : ""
    );
    if (currType.length > 0) {
      currType = currType[0].docType;
      allTypes.push(currType.toLowerCase());
    }
    if (localStorage.getItem("LastRow")) {
      const lastDeleteType = localStorage.getItem("LastRow").toLowerCase();
      const index = allTypes.indexOf(lastDeleteType);
      allTypes.splice(index, 1);
      localStorage.removeItem("LastRow");
    }
    allTypes.includes("noc")
      ? this.props.setFields((prev) => {
        return { ...prev, noc: 1 };
      })
      : this.props.setFields((prev) => {
        return { ...prev, noc: 0 };
      });
    allTypes.includes("nda")
      ? this.props.setFields((prev) => {
        return { ...prev, nda: 1 };
      })
      : this.props.setFields((prev) => {
        return { ...prev, nda: 0 };
      });
    allTypes.includes("msw/sow") ||
      allTypes.includes("msw") ||
      allTypes.includes("sow")
      ? this.props.setFields((prev) => {
        return { ...prev, msw: 1 };
      })
      : this.props.setFields((prev) => {
        return { ...prev, msw: 0 };
      });
  }

  $decideDownloadOrView(resourceName, queryName, fileparam, OpName) {
    if (OpName === "Download") {
      let queryFill = queryName
        .substring(queryName.indexOf("{") + 1)
        .split("(")[0]
        .trim();
      fetchMethod(queryName, fileparam)
        .then((res) => res.json())
        .then((res) => {
          if (res.errors) throw new Error(res.errors[0].message);
          else {
            downloadjs(
              Buffer.from(res.data[`${queryFill}`].fileData, "base64").toString(
                "binary"
              ),
              res.data[`${queryFill}`].fileName,
              res.data[`${queryFill}`].fileType
            );
          }
        })
        .catch((err1) => {
          this.setState({
            show: false,
          });
          swal(err1.message);
        });
    } else if (OpName === "crypt") {
      this.viewEncryptFile(queryName, fileparam);
    } else {
      fileparam.path =
        resourceName === "clientDocuments"
          ? fileparam.path.substring(
            fileparam.path.indexOf("clientDocuments") - 1
          )
          : fileparam.path;
      let url = new URL(URIDATA.queryFile);
      let params = {
        id: localStorage.getItem("id"),
        token: `${localStorage.getItem("token")}`,
        userId: fileparam.id,
        pathOfFile: fileparam.path,
        documentType: resourceName,
      };
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );
      fetchFile(url)
        .then((res) => {
          if (res.status && res.status === 404) {
            throw new Error(
              "Sorry, the file you have requested does not exist."
            );
          } else {
            window.open(res.url);
          }
        })
        .catch((err) => {
          this.setState({ show: false });
          swal(err.message);
        });
    }
  }

  insertDoctype() {
    if (this.state.docTypeName.length > 0) {
      mutationQueries(addDocType, {
        doctype: this.state.docTypeName,
        mandatory: this.state.isChecked,
      })
        .then(() => {
          fetchMethod(getAllDoctTypes)
            .then((res2) => res2.json())
            .then((res3) => {
              this.setState({
                allDocTypes: res3.data.getAllDocType,
                docTypeName: "",
                disable: "auto",
                visibleDocType: "none",
              });
            })
            .catch((err2) => {
              this.setState({
                show: false,
              });
              swal(err2.message);
            });
        })
        .catch(() => swal("Error !! Please Try after sometime.."));
    } else {
      swal("Please the Doctype");
    }
  }

  showAddDocTypeButton() {
    this.promptbox.current.style.pointerEvents = "none";
    this.setState({
      visibleDocType: "block",
      disable: "none",
    });
  }

  downladtheFile(path, fileId, OperationName) {
    //User Documents Downloading...!!!
    this.$decideDownloadOrView(
      "clientDocuments",
      DownloadFile,
      {
        id: this.state.id,
        path: path,
        flag: "Client",
        docId: fileId,
        adminId: localStorage.getItem("id"),
      },
      OperationName
    );
  }

  findEmailerPhotoId() {
    for (let val of this.state.allDocTypes) {
      if (val.docType === "Emailer-Photo") return val.id;
    }
    return null;
  }

  setQueryVar() {
    return {
      id: this.state.id,
      file: this.state.file,
      docName: this.state.docName,
      docTypeId: this.state.labelSelected,
    };
  }

  checkOp(value) {
    value === 1
      ? this.setState({ submitError: "Document name already exists" })
      : this.setState({
        submitError: "Document name is same as previous name",
      });
    return false;
  }

  validateFile(value) {
    if (
      this.state.docName &&
      this.state.labelSelected &&
      (this.state.fileName || value === 0)
    ) {
      const flag = this.state.allUserDocs.some(
        (item) =>
          item.docName.toLowerCase() === this.state.docName.toLowerCase()
      );
      return flag ? this.checkOp(value) : true;
    } else {
      let error = !this.state.docName ? "Document name is required" : "";
      console.log("name", error);
      error =
        error === "" && !this.state.labelSelected
          ? "Document type is required"
          : error;
      console.log("type", error);
      error =
        error === "" && !this.state.file && value === 1
          ? "Please select a file to upload"
          : error;
      console.log("file", error);
      this.setState({ submitError: error });
      return false;
    }
  }

  save(value) {
    let address, getaddress;
    let query = this.setQueryVar();
    if (this.validateFile(value)) {
      this.setState({
        visible: "none",
        labelSelected: null,
        file: "",
        docName: "",
        fileName: "",
        temp: "",
      });
      localStorage.removeItem("DocType1");
      localStorage.removeItem("DocType");
      if (value === 1) {
        address = uploadUserDocFiles;
        getaddress = getData;
        let emailerPhotoId = this.findEmailerPhotoId();
        if (
          emailerPhotoId != null &&
          this.state.labelSelected === emailerPhotoId
        ) {
          const body = new FormData();
          let imageFile = this.state.file;
          let blob = imageFile.slice(0, imageFile.size, "image/png");
          imageFile = new File([blob], `${this.state.id}.png`, {
            type: "image/png",
          });
          body.append("files", imageFile);
          let url = URIDATA.urlUpload;
          let url2 = URIDATA.url2;
          if (window.location.hostname !== "localhost") {
            let urlGraphql = url2.split("/graphql");
            url = `${urlGraphql[0]}/api/containers/emailer_photo`;
          }
          fetch(`${url}/upload`, {
            method: "post",
            body: body,
          });
        }
        mutationQueries(address, query).then(() => {
          fetchMethod(getaddress, { id: this.props.id })
            .then((res1) => res1.json())
            .then((res2) => {
              this.setState({
                allUserDocs: res2.data.queryClientAllFiles,
              });
            })
            .catch((err13) => {
              this.setState({
                show: false,
              });
              swal(err13.message);
            });
        });
      }
      if (value === 0) {
        address = updateUserDocsInfo;
        getaddress = getData;
        let query1 = {
          id: this.state.id,
          docName: this.state.docName,
          docTypeId: this.state.labelSelected,
          docPath: this.state.filePath,
          temp: this.state.temp,
          file: this.state.file,
          docId: this.state.docId,
        };
        mutationQueries(address, query1).then(() => {
          fetchMethod(getaddress, { id: this.props.id })
            .then((res2) => res2.json())
            .then((res3) => {
              this.setState({
                allUserDocs: res3.data.queryClientAllFiles,
                docName: "",
                labelSelected: null,
                visible: "none",
                otherTextField: "",
                docId: "",
                updateOrinsert: "",
                filename: "",
                temp: "",
              });
            })
            .catch((errf) => {
              this.setState({
                show: false,
              });
              swal(errf.message);
            });
        });
      }
      this.updateFields();
    }
  }

  fileUpload([file]) {
    this.setState({ submitError: "" });
    this.setState({
      file: file,
      fileName: file.name,
      temp: "newFileSelected",
    });
  }

  deleteDoc(path, name, docid, docType) {
    localStorage.setItem("LastRow", docType);
    let address, getaddress;
    address = deleteUserDocs;
    getaddress = getData;

    let query = {
      id: this.props.id,
      docPath: this.state.filePath || path,
      docName: this.state.fileName || name,
      docId: this.state.docId || docid,
    };
    mutationQueries(address, query)
      .then(() => {
        this.setState({
          show: false,
        });
        fetchMethod(getaddress, { id: this.props.id })
          .then((res) => res.json())
          .then((res) => {
            this.setState({
              file: "",
              fileName: "",
              filePath: "",
              allUserDocs: res.data.queryClientAllFiles,
            });
            swal("Document has been deleted!", {
              icon: "success",
            });
          })
          .catch((err) => {
            this.setState({
              show: false,
              file: "",
              fileName: "",
              filePath: "",
            });
            swal(err.message);
          });
      })
      .catch(() => {
        this.setState({
          show: false,
          file: "",
          fileName: "",
          filePath: "",
        });
        swal("Sorry, the file you have requested to delete does not exist.");
      });
    this.updateFields();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.id !== this.props.id) {
      this.setState(
        {
          id: nextProps.id,
        },
        () => {
          const query = `{
          getAllDocType{
            docType
            id
          }
        }`;
          fetchMethod(query)
            .then((res1) => res1.json())
            .then((res2) => {
              this.setState({
                allDocTypes: res2.data.getAllDocType,
              });
            })
            .catch((errd) => {
              this.setState({
                show: false,
              });
              swal(errd.message);
            });
          fetchMethod(getData, { id: this.props.id })
            .then((res3) => res3.json())
            .then((res4) => {
              this.setState({
                allUserDocs: res4.data.queryClientAllFiles,
              });
            })
            .catch((errb) => {
              this.setState({
                show: false,
              });
              swal(errb.message);
            });
        }
      );
    }
  }
  componentWillMount() {
    fetchMethod(getAllDoctTypes)
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          allDocTypes: res.data.getAllDocType,
        });
      })
      .catch((errr) => {
        this.setState({
          show: false,
        });
        swal(errr.message);
      });
    fetchMethod(getData, { id: this.props.id })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          allUserDocs: res.data.queryClientAllFiles,
        });
      })
      .catch((error) => {
        this.setState({
          show: false,
        });
        swal(error.message);
      });
  }

  manageDocType(event, _index, value2) {
    if (event.target.innerText !== "Others") {
      this.setState({
        labelSelected: value2,
      });
    }

    if (event.target.innerText === "Others") {
      this.setState({
        labelSelected: value2,
      });
    }
  }

  doctypeHandler = (_, newValue) => {
    this.setState({ submitError: "" });
    if (newValue) {
      localStorage.setItem("DocType1", newValue);
      const value = Object.values(this.state.allDocTypes).filter(
        (item) => item.docType === newValue
      )[0];
      this.setState({
        labelSelected: value.id,
      });
    }
  };

  labelHandler(bool) {
    this.setState({ isLabelClicked: bool });
  }
  render() {
    const stye = { margin: "4% 3%" };

    return (
      <div style={{ stye }} className="UsersDetailLabel">
        <div
          className="row"
          style={{ marginTop: "1rem", marginBottom: "2rem" }}
        >
          <Button
            style={{ padding: "8px 16px 8px 16px" }}
            color="primary"
            size="small"
            variant="contained"
            onClick={(e) => {
              localStorage.removeItem("DocType1");
              this.setState({
                visible: "block",
                updateOrinsert: "insert",
              });
            }}
          >
            ADD {this.state.resumeorDocs}
          </Button>
        </div>
        {/*apple*/}

        <div>
          <table className="table">
            <tr className="header">
              <th>{this.state.resumeorDocs} Name</th>
              <th
                className="theader"
                style={{ display: this.state.showDoctypeButton }}
              >
                Type
              </th>
              <th className="theader">Uploaded On</th>
              <th className="theader" colSpan={4}>
                Actions
              </th>
            </tr>
            <br />
            <tbody>
              {this.state.allUserDocs.map((item, key) => {
                return (
                  <tr key={key} className="tablerow">
                    <td style={{ textAlign: "center" }}>
                      <a href="void(0)" className="MousePointer">{item.docName}</a>
                    </td>
                    <td
                      style={{
                        display: this.state.showDoctypeButton,
                        textAlign: "center",
                      }}
                    >
                      {item.docType}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {moment(item.createdAt).format("DD-MM-YYYY")}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <a
                        href="void(0)"
                        style={{ marginLeft: "6px" }}
                        className="MousePointer"
                        onClick={(e) => {
                          fetchMethod(modifyingDocs, { docId: item.id })
                            .then((res) => res.json())
                            .then((res) => {
                              localStorage.setItem("DocType", item.docType);
                              this.setState({
                                docName:
                                  res.data.queryModifiedClientInfo.docName,
                                labelSelected:
                                  res.data.queryModifiedClientInfo.docTypeId,
                                visible: "block",
                                updateOrinsert: "update",
                                docId: res.data.queryModifiedClientInfo.id,
                                filePath:
                                  res.data.queryModifiedClientInfo.docPath,
                              });
                            })
                            .catch((errmm) => {
                              this.setState({
                                show: false,
                              });
                              swal(errmm.message);
                            });
                        }}
                      >
                        <EditIcon />
                      </a>
                      <a
                        href="void(0)"
                        style={{ marginLeft: "6px" }}
                        className="MousePointer"
                        onClick={(e) => {
                          swal({
                            title: "Are you sure?",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                          }).then((res) =>
                            res
                              ? this.deleteDoc(
                                item.docPath,
                                item.docName,
                                item.id,
                                item.docType
                              )
                              : ""
                          );
                        }}
                      >
                        <DeleteForeverIcon />
                      </a>
                      <a
                        href="void(0)"
                        style={{ marginLeft: "6px" }}
                        className="MousePointer"
                        onClick={(e) =>
                          this.downladtheFile(item.docPath, item.id, "Download")
                        }
                      >
                        <DownloadIcon />
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="row">
            <div
              id="doctypeform-box"
              className="popup"
              style={{ display: this.state.visibleDocType }}
            >
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="row">
                    <div className="col-md-8">
                      <TextField
                        variant="outlined"
                        id="name"
                        style={{ width: "250px" }}
                        label="Enter Document type"
                        type="text"
                        value={this.state.docTypeName}
                        onChange={(e) =>
                          this.setState({ docTypeName: e.target.value })
                        }
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          readOnly: false,
                        }}
                        required
                      />
                    </div>
                  </div>

                  <div className="row" style={{ margin: "5%" }} hidden>
                    <div className="col-md-4" style={{ textAlign: "left" }}>
                      <label className="projectinputLabel">Mandatory</label>
                    </div>
                    <div className="col-md-8" style={{ marginLeft: "20px" }}>
                      <input
                        type="checkBox"
                        id="checkbox"
                        onChange={this.onClickCheckBox.bind(this)}
                        checked={this.state.isChecked}
                        className="newCheckox"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div id="dialog-edit-button">
                      <Button
                        style={{ height: "40px", margin: "5px 5px" }}
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={() => {
                          this.setState({
                            visibleDocType: "none",
                            disable: "auto",
                            file: "",
                            fileName: "",
                            filePath: "",
                          });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        style={{ height: "40px", margin: "5px 5px" }}
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={this.insertDoctype}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="prompt-box"
            className="prompt-box"
            style={{ display: this.state.visible }}
          >
            <div
              id="promptform-box"
              ref={this.promptbox}
              style={{
                display: this.state.visible,
                pointerEvents: this.state.disable,
              }}
            >
              <div id="new" />

              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="promptInput">
                    <div
                      className="row"
                      style={{
                        display:
                          this.state.resumeorDocs === "Resume"
                            ? "none"
                            : "block",
                      }}
                    >
                      <div className="col-md-8">
                        <TextField
                          variant="outlined"
                          id="name"
                          inputProps={{ readOnly: false }}
                          style={{ width: "250px", borderColor: "red" }}
                          label={this.state.resumeorDocs + " Name"}
                          type="text"
                          value={this.state.docName}
                          onChange={(e) => {
                            this.setState({
                              docName: e.target.value,
                            });
                            this.setState({ submitError: "" });
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                    </div>
                    <div className="row" style={{ display: "flex" }}>
                      <Autocomplete
                        size="large"
                        style={{ width: "250px", marginTop: "10px" }}
                        value={
                          localStorage.getItem("DocType") ||
                          localStorage.getItem("DocType1")
                        }
                        options={Object.values(this.state.allDocTypes).map(
                          (item) => item.docType
                        )}
                        getOptionLabel={(option) => option}
                        defaultValue={this.state.allDocTypes[0]}
                        onChange={this.doctypeHandler}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Document types"
                            placeholder="Document types"
                          />
                        )}
                      />
                      <Button
                        style={{ height: "40px", margin: "20px 50px" }}
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={this.showAddDocTypeButton}
                      >
                        + ADD DOCTYPE
                      </Button>
                    </div>

                    <div className="row" style={{ marginTop: "10px" }}>
                      <div className="col-md-2 col-sm-4">
                        {
                          <label>
                            Filename :{" "}
                            {this.state.fileName ||
                              localStorage.getItem("DocType")
                              ? this.state.docName
                              : this.state.fileName}{" "}
                          </label>
                        }
                      </div>
                      <Dropzone onDrop={this.fileUpload}>
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps({ className: "dropzone" })}>
                              <input {...getInputProps()} />
                              <p
                                style={{
                                  width: "100%",
                                  color: "green",
                                  textAlign: "center",
                                }}
                              >
                                Drop some files here, or click to select files
                              </p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </div>
              </div>

              <div id="dialog-edit-button" style={{ marginTop: "10px" }}>
                <p style={{ color: "red", height: "30px" }}>
                  {this.state.submitError}
                </p>
                <Button
                  style={{ height: "40px" }}
                  color="primary"
                  size="small"
                  onClick={(e) => {
                    localStorage.removeItem("DocType1");
                    localStorage.removeItem("DocType");
                    this.setState({
                      docName:
                        this.state.resumeorDocs === "Resume" ? "Resume" : "",
                      visible: "none",
                      isLabelClicked: false,
                      labelSelected: null,
                      fileName: "",
                    });
                  }}
                  variant="contained"
                >
                  Cancel
                </Button>

                <Button
                  style={{ height: "40px", marginLeft: "10px" }}
                  color="primary"
                  size="small"
                  onClick={(e) =>
                    this.state.updateOrinsert === "insert"
                      ? this.save(1)
                      : this.save(0)
                  }
                  variant="contained"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
