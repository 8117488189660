import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import TemporaryDrawer from "./SideBar";
import routesWithPermission from "./routes";


const App = ({ match }) => {
  const [isToken, setIsToken] = useState(true);
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    const _isToken = localStorage.getItem("token");

    if (_isToken == null || _isToken === "undefined") {
      setIsToken(false);
    } else {
      const _routes = routesWithPermission();
      setIsToken(true);
      setRoutes(_routes);
    }
  }, []);

  return (
    <div>
      {isToken ? (
        <>
          <TemporaryDrawer />
          {routes && routes.length > 0 && (
            <Switch>
              <>
                {routes.map((route, i) => {
                  return (
                    <Route
                      key={i}
                      exact
                      path={route.path}
                      component={route.component}
                    />
                  );
                })}
              </>
            </Switch>
          )}
        </>
      ) : (
        <Redirect to="/login" />
      )}
    </div>
  );
};

export default withRouter(App);
