import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { gql, useMutation } from "@apollo/client";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {
  DataGrid as DataGridElement,
  GridToolbar,
} from "@material-ui/data-grid";
import Edit from "@material-ui/icons/Edit";
import TrashIcon from "@material-ui/icons/Delete";
import swal from "sweetalert";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import URLDATA, { authHeader } from "../config";
import { useHistory } from "react-router";
import { getAllVendor } from "../query.js";
import {
  usePersistedState,
  clearOnReloadPage,
} from "../utils/usePersistedState";
import { customNoRowsOverlay } from "../utils/overlay";
const styles = () => ({});

const DataGrid = (props) => {
  const [page, setPage] = usePersistedState("Page", 0, "vendor");
  const [pageSize, setPageSize] = usePersistedState("PageSize", 10, "vendor");
  const [rows, setRows] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [prevPage, setPrevPage] = useState(0);

  const fetchVendorList = () => {
    fetch(URLDATA.url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
        xcvtoken: localStorage.getItem("id"),
      },
      body: JSON.stringify({
        query: getAllVendor,
        variables: {},
        context: { clientName: "server1" },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        localStorage.removeItem("EditRowData");
        setRows(res.data.allVendors.Vendors.filter((data) => data.active === 1));
        setTotalCount(
          res.data.allVendors.Vendors
            ? res.data.allVendors.Vendors?.filter((data) => data.active === 1)
              .length
            : 1000
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    if (filters.value !== undefined && filters.value !== "") {
      setPrevPage(page);
      setPage(0);
    } else if (prevPage !== 0) setPage(prevPage);
  }, [filters]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setColumns(gridColumns);
    clearOnReloadPage();
    localStorage.setItem("RowPage", "vendor");
    fetchVendorList();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (param) => {
    setPage(param);
  };

  useEffect(() => {
    setPage(0);
  }, [pageSize]);// eslint-disable-line react-hooks/exhaustive-deps

  const handlePageSizeChange = async (params) => {
    setPageSize(params);
  };

  let router = useHistory();
  let gridColumns = [
    { field: "name", headerName: "Name", width: 200 },
    {
      field: "email",
      headerName: "Email",
      width: 300,
      renderCell: (params) => {
        return (
          <a
            style={{ textDecoration: "none" }}
            href={`mailto: ${params.row.email}`}
          >
            {params.row.email}
          </a>
        );
      },
    },
    { field: "contactnumber", headerName: "Contact Number", width: 150 },
    { field: "contactperson", headerName: "Contact Person", width: 200 },
    { field: "address", headerName: "Address", width: 150 },
    {
      field: "numberofemployees",
      headerName: "Number of Employees",
      width: 150,
    },
    { field: "experties", headerName: "Experties", width: 150 },
    { field: "othercontacts", headerName: "Other Contacts", width: 150 },
    {
      field: "rating",
      headerName: "Rating",
      width: 150,
      renderCell: (params) => {
        return <Rating name="read-only" value={params.row.rating} readOnly />;
      },
    },
    { field: "action", disableExport: true, headerName: "Actions", width: 150 },
  ].filter((item) => {
    return item.field !== "action";
  });

  gridColumns = [
    ...gridColumns,
    {
      disableExport: true,
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <strong>
          <Edit
            onClick={() => {
              localStorage.setItem("EditRowData", JSON.stringify(params.row));
              router.push({ pathname: "/edit-vendor" });
            }}
            style={{ marginInline: "4px", cursor: "pointer" }}
          />

          <TrashIcon
            onClick={
              // handleClickOpen
              () => {
                swal({
                  title: "Are you sure?",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    props.deleteHandler({
                      id: params.row.id,
                    });
                    swal("Vendor has been deleted!", {
                      icon: "success",
                    });
                    fetchVendorList();
                  }
                });
              }
            }
            style={{ marginInline: "4px", cursor: "pointer" }}
          />
        </strong>
      ),
    },
  ];
  return (
    <>
      <Grid container xs={12} style={{ height: "86vh", marginTop: "2rem" }}>
        <DataGridElement
          columns={columns}
          rows={rows}
          rowCount={totalCount}
          rowsPerPageOptions={[10, 15, 20, 25, 50, 75, 100]}
          pageSize={pageSize || 0}
          page={page || 0}
          density="compact"
          components={{
            Toolbar: GridToolbar,
            NoRowsOverlay: customNoRowsOverlay,
          }}
          onFilterModelChange={(model) => setFilters(model?.items[0])}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          loading={loading}
        />
      </Grid>
    </>
  );
};

const VendorsInner = () => {
  const [fields, setFields] = React.useState({});
  const [formError, setFormError] = React.useState("");
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const gqlMutation1 = (function () {
    let code = `  
      mutation (
        $id: Float
        $active: Float
        $address: String
        $contactnumber: String
        $contactperson: String
        $createdon: Date!
        $email: String
        $experties: String
        $name: String
        $numberofemployees: Float
        $rating: Float
        $othercontacts: String
      ) {
        saveVendors(
          obj: {
            id: $id
            active: $active
            address: $address
            contactnumber: $contactnumber
            contactperson: $contactperson
            createdon: $createdon
            email: $email
            experties: $experties
            name: $name
            numberofemployees: $numberofemployees
            rating:$rating
            othercontacts: $othercontacts
          }
        ) {
          id
          active
          address
          contactnumber
          contactperson
          createdon
          email
          experties
          name
          numberofemployees
          rating
          othercontacts
        }
      }`;
    return gql`
      ${code}
    `;
  })();
  const [saveMutation, { error: submitError }] = useMutation(gqlMutation1, {
    context: authHeader(),
  });
  useEffect(() => {
    let fieldsA = {};
    let data = JSON.parse(localStorage.getItem("EditRowData"));
    if (data) {
      Object.entries(data).forEach(() => {
        setFields({ ...fieldsA });
      });
    }
  }, []);

  useEffect(() => {
    if (submitError) {
      setFormError(submitError.toString());
    }
  }, [submitError]);

  const deletePost = (field) => {
    let fieldsT = {};
    Object.assign(fieldsT, {
      id: field.id,
      address: field.address,
      contactnumber: field.contactnumber,
      contactperson: field.contactperson,
      createdon: new Date(),
      email: field.email,
      experties: field.experties,
      name: field.name,
      numberofemployees: field.numberofemployees,
      rating: field.rating,
      othercontacts: field.othercontacts,
      active: 0,
    });
    saveMutation({
      variables: {
        ...fieldsT,
      },
      context: authHeader(),
    });
  };

  const deleteHandler = (item) => {
    deletePost({ id: item.id, ...item });
  };

  return (
    <Grid>
      <Grid container xs={12} md={12} spacing={3}>
        <div className="inner-header">
          <div>
            <h4 className="page-title">Manage Vendors</h4>
          </div>
          <div>
            <Link className="navNames" to="/add-vendor">
              <Button color="primary" variant="outlined">
                Add New Vendor
              </Button>
            </Link>
          </div>
        </div>
      </Grid>
      <form id="inputform1">
        <div>
          <Grid
            style={{ padding: "0px 15px", margin: "0px" }}
            container
            xs={12}
            md={12}
          >
            <Grid item xs={12} md={6}>
              <div style={{ color: "red" }}>{formError}</div>

              <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                disableEscapeKeyDown={true}
                aria-describedby="alert-dialog-description"
                id="dialogbox"
              >
                <DialogContent id="contentvendor">
                  <div style={{ width: "300px" }} id="divvendor">
                    <DialogContentText id="alert-dialog-description">
                      Success!!
                    </DialogContentText>
                  </div>
                </DialogContent>
                <DialogActions id="actionsvendor">
                  <Button onClick={handleDialogClose} color="primary" autoFocus>
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <DataGrid deleteHandler={deleteHandler} parentState={fields} />
          </Grid>
        </div>
      </form>
    </Grid>
  );
};
VendorsInner.propTypes = {
  classes: PropTypes.object.isRequired,
};
const CompWithStyleInner = withStyles(styles, { withTheme: true })(
  VendorsInner
);
function VendorList() {
  return <CompWithStyleInner />;
}
export default VendorList;
