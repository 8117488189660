import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { CellModal } from "../reuseableComponents";
import { withStyles } from "@material-ui/core/styles";
import { gql, useMutation } from "@apollo/client";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {
  DataGrid as DataGridElement,
  GridToolbar,
} from "@material-ui/data-grid";
import Edit from "@material-ui/icons/Edit";
import moment from "moment";
import Button from "@mui/material/Button";
import URLDATA, { authHeader } from "../config";
import { useHistory } from "react-router";
import {
  usePersistedState,
  clearOnReloadPage,
} from "../utils/usePersistedState";
import { customNoRowsOverlay } from "../utils/overlay";

const styles = () => ({});

const DataGrid = () => {
  const [columns, setColumns] = useState([]);
  const [page, setPage] = usePersistedState("Page", 0, "resignation");
  const [pageSize, setPageSize] = usePersistedState(
    "PageSize",
    10,
    "resignation"
  );
  const [rows, setRows] = React.useState([]);
  const [popupData, setPopupData] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [totalCount, setTotalCount] = React.useState(0);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [prevPage, setPrevPage] = useState(0);
  const showAllData = (data) => {
    const { value } = data;
    popupHandler(true, value.split("\n"));
  };
  useEffect(() => {
    if (filters.value !== undefined && filters.value !== "") {
      setPrevPage(page);
      setPage(0);
    } else if (prevPage !== 0) setPage(prevPage);
  }, [filters]);// eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setPage(0);
  }, [pageSize]);// eslint-disable-line react-hooks/exhaustive-deps
  const fetchJobList = () => {
    fetch(URLDATA.url1, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        query: `query ($first:Int,$last:Int) {
              getUser(pageNumber:$first,pageSize:$last,checkStatus:3){
            id
            name
            projectTitle
            type
            resignation
            lastDate
            notes
            isActive
          }}`,
        variables: {
          first: 0,
          last: 100,
        },
        context: { clientName: "server2" },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        let data = res?.data?.getUser;
        data = data.filter((item) => item.isActive !== 0);
        data.map((item, index) => {// eslint-disable-line
          if (index > 0 && data[index - 1]?.id === item.id) {
            item.projectTitle =
              data[index - 1]?.projectTitle + ", " + item.projectTitle;
            data[index - 1] = null;
          } else return item;
        });
        data = data.filter((item) => item !== null);
        setRows(data);
        setTotalCount(data ? data.length : 1000);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    setColumns(gridcolumns);
    clearOnReloadPage();
    localStorage.setItem("RowPage", "resignation");
    fetchJobList();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (param) => {
    setPage(param);
  };
  const handlePageSizeChange = (params) => {
    setPageSize(params);
  };

  const popupHandler = (isOpens, data) => {
    setIsOpen(isOpens);
    setPopupData(data);
  };
  let router = useHistory();
  let gridcolumns = [
    { field: "name", headerName: "Name", width: 230 },
    { field: "type", headerName: "Designation", width: 300 },
    {
      field: "projectTitle",
      headerName: "Current Projects",
      width: 200,
      renderCell: (params) => {
        const data = { value: params?.row?.projectTitle || "" };
        return <CellModal data={data} handler={showAllData} />;
      },
    },
  ].filter((item) => {
    return item.field !== "action";
  });

  gridcolumns = [
    ...gridcolumns,
    {
      field: "resignation",
      headerName: "Resignation Date",
      width: 150,
      renderCell: (params) => (
        <p>
          {params.value !== null
            ? moment(params.value).format("DD-MM-YYYY")
            : ""}
        </p>
      ),
    },
    {
      field: "End Date",
      headerName: "End Date",
      width: 150,
      renderCell: (params) => {
        let date = moment(params.row.resignation).format("DD-MM-YYYY");
        date = moment(date, "DD-MM-YYYY").add(3, "month");
        if (params.row.lastDate) {
          if (moment().isAfter(params.row.lastDate))
            return (
              <p style={{ color: "red" }}>
                {moment(params?.row?.lastDate).format("DD-MM-YYYY")}
              </p>
            );
          else if (Math.abs(moment().diff(params.row.lastDate, "days")) > 30)
            return <p>{moment(params?.row?.lastDate).format("DD-MM-YYYY")}</p>;
          else
            return (
              <p id="1" style={{ color: "red" }}>
                {moment(params?.row?.lastDate).format("DD-MM-YYYY")}
              </p>
            );
        } else {
          if (moment().isAfter(date._d))
            return (
              <p style={{ color: "red" }}>
                {moment(date._d).format("DD-MM-YYYY")}
              </p>
            );
          else if (Math.abs(moment().diff(date._d, "days")) > 30)
            return <p>{moment(date._d).format("DD-MM-YYYY")}</p>;
          else
            return (
              <p id="2" style={{ color: "red" }}>
                {moment(date._d).format("DD-MM-YYYY")}
              </p>
            );
        }
      },
    },
    { field: "notes", headerName: "Notes", width: 350 },
    {
      disableExport: true,
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        // added hover effect for edit icon //
        <strong title="edit">
          <Edit
            onClick={() => {
              localStorage.setItem("EditRowData", JSON.stringify(params.row));
              router.push({ pathname: "/edit-resignation" });
            }}
            style={{ marginInline: "4px", cursor: "pointer" }}
          />
        </strong>
      ),
    },
  ];
  return (
    <>
      <Grid container xs={12}>
        <Dialog onClose={() => popupHandler(false, "")} open={isOpen}>
          <DialogContent>{popupData}</DialogContent>
        </Dialog>
        <DataGridElement
          style={{ height: "90vh" }}
          columns={columns}
          rows={rows}
          rowCount={totalCount}
          rowsPerPageOptions={[10, 15, 20, 25, 50, 75, 100]}
          pageSize={pageSize}
          page={page}
          density="compact"
          components={{
            Toolbar: GridToolbar,
            NoRowsOverlay: customNoRowsOverlay,
          }}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          loading={loading}
          onFilterModelChange={(model) => setFilters(model?.items[0])}
        />
      </Grid>
    </>
  );
};

const ResignationListInner = () => {
  const [fields, setFields] = useState({});
  const [formError, setFormError] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClosed = () => {
    setDialogOpen(false);
  };

  const gqlMutation55 = (function () {
    let code = `  
        mutation(
          $id: Float,
          $active: Float,
          $createdby:Float,
          $createdon: Date!,
          $title: String,
          $description: String,
          $internalnotes: String,
          $technologies: String,
          $screeningtesturl: String,
          $updatedby: Float,
          $updatedon: Date!) {
                saveJobdescription(obj: {
          id:$id
          active:$active,
          createdby:$createdby,
          createdon:$createdon,
          title:$title,
          description:$description,
          internalnotes:$internalnotes,
          technologies:$technologies,
          screeningtesturl:$screeningtesturl,
          updatedby:$updatedby,
          updatedon:$updatedon,        
                }) {
          id
          active
          createdby
          createdon
          title
          description
          internalnotes
          technologies
          screeningtesturl
          updatedby
          updatedon   
                  }
          }`;
    return gql`
      ${code}
    `;
  })();
  const [saveMutation55, { error: submitError }] = useMutation(gqlMutation55, {
    context: authHeader(),
  });
  useEffect(() => {
    let fieldsTe = {};
    let datanew = JSON.parse(localStorage.getItem("EditRowData"));
    if (datanew) {
      Object.entries(datanew).forEach(() => {
        setFields({ ...fieldsTe });
      });
    }
  }, []);

  useEffect(() => {
    if (submitError) {
      setFormError(submitError.toString());
    }
  }, [submitError]);

  const deletePost = (field) => {
    let fieldsTe = {};
    Object.assign(fieldsTe, {
      id: field.id,
      title: field.title,
      description: field.description,
      internalnotes: field.internalnotes,
      createdon: new Date(),
      createdby: field.createdby,
      updatedby: field.updatedby,
      updatedon: new Date(),
      technologies: field.technologies,
      screeningtesturl: field.screeningtesturl,
      active: 0,
    });
    saveMutation55({
      variables: {
        ...fieldsTe,
      },
      context: authHeader(),
    });
  };

  const deleteHandler = (item) => {
    deletePost({ id: item.id, ...item });
  };
  return (
    <Grid>
      <Grid container xs={12} md={12} spacing={3}>
        <div className="inner-header">
          <div>
            <h4 className="page-title">Resigned Employees</h4>
          </div>
          <div>
            <Link className="navNames" to="/add-resignation">
              {/* <Button color="primary" variant="outlined"> Add Notes </Button> */}
            </Link>
          </div>
        </div>
      </Grid>

      <form>
        <div>
          <Grid
            style={{ padding: "0 15px", margin: "0px" }}
            container
            xs={12}
            md={12}
            spacing={3}
          >
            <Grid item xs={12} md={6}>
              <div style={{ color: "red" }}>{formError}</div>

              <Dialog
                open={dialogOpen}
                onClose={handleDialogClosed}
                disableEscapeKeyDown={true}
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <div style={{ width: "300px" }}>
                    <DialogContentText id="alert-dialog-description">
                      {"Success!!"}
                    </DialogContentText>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleDialogClosed}
                    color="primary"
                    autoFocus
                  >
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <DataGrid deleteHandler={deleteHandler} parentState={fields} />
          </Grid>
        </div>
      </form>
    </Grid>
  );
};
ResignationListInner.propTypes = {
  classes: PropTypes.object.isRequired,
};
const CompWithStyleInner = withStyles(styles, { withTheme: true })(
  ResignationListInner
);
function ResignationList() {
  return <CompWithStyleInner />;
}
export default ResignationList;
