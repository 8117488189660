import React from 'react';

export function usePersistedState(key, defaultValue, page) {
  if (localStorage.getItem("RowPage") !== page) {
    localStorage.removeItem(`PageSize`);
    localStorage.removeItem(`Page`);
    localStorage.removeItem(`LastCount`);
    localStorage.removeItem(`FirstCount`);
  }
  const [state, setState] = React.useState(() => {
    const persistedState = localStorage.getItem(key);
    return persistedState ? JSON.parse(persistedState) : defaultValue;
  });

  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);
  return [state, setState];
}

export function clearOnReloadPage() {
  window.onunload = function () {
    localStorage.removeItem(`PageSize`);
    localStorage.removeItem(`Page`);
    localStorage.removeItem(`LastCount`);
    localStorage.removeItem(`FirstCount`);
    localStorage.removeItem(`RowPage`);
    localStorage.removeItem(`column`)
  }
}

export function clearAllPersistedState() {
  localStorage.removeItem(`PageSize`);
  localStorage.removeItem(`Page`);
  localStorage.removeItem(`LastCount`);
  localStorage.removeItem(`FirstCount`);
  localStorage.removeItem(`RowPage`);
  localStorage.removeItem(`column`)
  localStorage.removeItem(`RowDetails`)
  localStorage.removeItem("rowData")
}