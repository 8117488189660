import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useHistory, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { gql, useMutation } from "@apollo/client";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import moment from "moment";
import ClassicEditor from "classic-code-block";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { getQuestionDetails } from "../query";
import "../All.css";
import URLDATA, { authHeader } from "../config";

const API_URL = URLDATA.urlCkEditor;
const UPLOAD_ENDPOINT = "upload";

function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        const body = new FormData();
        loader.file.then((file) => {
          if (file.size > 2097152) {
            reject("File must be less than 2MB");
          } else {
            body.append("files", file);
            fetch(`${API_URL}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then(() => {
                resolve({
                  default: `${API_URL}/download/${file.name}`,
                });
              })
              .catch((err) => {
                reject(err);
              });
          }
        });
      });
    },
  };
}
function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}

const styles = () => ({});

const QuestionsInner = () => {
  const [fields, setFields] = React.useState({
    skill: [],
    skillId: [],
    correctanswer: [],
    option1: localStorage.getItem("EditRowData")
      ? JSON.parse(localStorage.getItem("EditRowData")).option1
      : "",
    option2: localStorage.getItem("EditRowData")
      ? JSON.parse(localStorage.getItem("EditRowData")).option2
      : "",
    option3: localStorage.getItem("EditRowData")
      ? JSON.parse(localStorage.getItem("EditRowData")).option3
      : "",
    option4: localStorage.getItem("EditRowData")
      ? JSON.parse(localStorage.getItem("EditRowData")).option4
      : "",
  });
  const [errors, setErrors] = useState({});
  const [formError, setFormError] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formProcessing, setFormProcessing] = useState(false);
  const [skillsData, setSkillsData] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const [QuestionVideo, setQuestionVideo] = useState(0);
  const [Option1Video, setOption1Video] = useState(0);
  const [Option2Video, setOption2Video] = useState(0);
  const [Option3Video, setOption3Video] = useState(0);
  const [Option4Video, setOption4Video] = useState(0);

  function toggleDisabled(val) {
    if ("question" === val) setQuestionVideo(!QuestionVideo);
    if ("option1" === val) setOption1Video(!Option1Video);
    if ("option2" === val) setOption2Video(!Option2Video);
    if ("option3" === val) setOption3Video(!Option3Video);
    if ("option4" === val) setOption4Video(!Option4Video);
  }

  let editorConfiguration = {
    extraPlugins: [uploadPlugin],
  };
  useEffect(() => {
    fetch(URLDATA.url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
        xcvtoken: localStorage.getItem("id"),
      },
      body: JSON.stringify({
        query: `
        query {
          allSkills{
            Skills {
              id
              name
            }
          }
        }`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setSkillsData(res.data.allSkills.Skills);
      });
  }, []);
  const filter = createFilterOptions();

  const complexityOptions = [
    {
      value: "Easy",
      text: "Easy",
      key: "dropdown_option_5CAD3247-40F6-4377-8675-FE579E658F41",
    },
    {
      value: "Beginner",
      text: "Beginner",
      key: "dropdown_option_0280154D-297C-4443-AE84-E5D09055B528",
    },
    {
      value: "Intermediate",
      text: "Intermediate",
      key: "dropdown_option_608F9823-BD73-4527-8749-BFD1EAFA0D4F",
    },
    {
      value: "Advance",
      text: "Advance",
      key: "FFF8C93E-3377-49E8-B046-CE077A89210D",
    },
  ];

  const typeOptions = [
    {
      value: "MCQ",
      text: "MCQ",
      key: "dropdown_option_5CAD3247-40F6-4377-8675-FE579E658F41",
    },
    {
      value: "TEXT",
      text: "Text",
      key: "dropdown_option_608F9823-BD73-4527-8749-BFD1EAFA0D4F",
    },
    {
      value: "IMGMCQ",
      text: "IMGMCQ",
      key: "dropdown_option_608F9767-BD73-4527-8749-BFD1EAFA0D4F",
    },
    {
      value: "AUDIO",
      text: "AUDIO/VIDEO",
      key: "dropdown_option_608F8709-BD73-4527-8749-BFD1EAFA0D4F",
    },
  ];

  let history = useHistory();

  const gqlMutation = (function () {
    let code = ` 
    mutation($id:Float,$complexity:String,$correctanswer:String,$createdat:Date!,$option1:String,$option2:String,$option3:String,$option4:String,$question:String, $skill:String, $skillid:String,$type:String,$updatedat:Date!,$duration:Float) {
      saveQuestions(obj: {id:$id,complexity: $complexity, corretanswer: $correctanswer, createdat: $createdat, option1: $option1, option2: $option2, option3: $option3, option4: $option4, question: $question, skill: $skill, skillid: $skillid, type: $type, updatedat: $updatedat,duration:$duration}) {
          id
        }
        }`;
    return gql`
      ${code}
    `;
  })();

  const convertToMinutes = (minute) => {
    return parseInt(minute) * 60000;
  };
  const [saveMutation, { loading, error: submitError }] =
    useMutation(gqlMutation, {
      variables: {
        complexity: fields.complexity,
        correctanswer: fields.correctanswer.toString(),
        option1: fields.option1,
        option2: fields.option2,
        option3: fields.option3,
        option4: fields.option4,
        question: fields.question,
        skill: fields.skill.toString(),
        skillid: fields.skillId && fields.skillId.toString(),
        type: fields.type,
        createdat: new Date(),
        duration: parseFloat(convertToMinutes(fields.duration)),
        updatedat: new Date(),
      },
      onCompleted: submitCompleted,
      context: authHeader(),
    });

  const handleDialogClose = () => {
    setDialogOpen(false);
    history.push("/test-questions");
    setFormProcessing(false);
  };
  function submitCompleted() {
    setFormProcessing(true);
    setDialogOpen(true);
  }
  let editableData = JSON.parse(localStorage.getItem("EditRowData"));
  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("EditRowData"));
    if (data) {
      data.duration = +data.duration.split(" ")[0];
      let dataValue = data;
      setFields({
        ...data,
        skill: dataValue.skill.split(","),
        skillId: dataValue.skillId && dataValue.skillId.split(","),
        correctanswer: dataValue.corretanswer.split(","),
      });
    }
  }, []);

  const handleValidation = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid3 = true;
    let fieldValue = e && e.target ? e.target.value : e;
    let updatedValue = fieldValue;
    if (
      (fieldname === undefined || fieldname === "question") &&
      fields.type !== "AUDIO"
    ) {
      if (fieldname === undefined) {
        updatedValue = fieldValue = fields["question"]
          ? fields["question"]
          : "";
      }

      delete errorsT["question"];

      if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        formIsValid3 = false;
        errorsT[`question`] = "Question is required";
      }
    }

    if (
      fields.type !== "TEXT" &&
      fields.type !== "IMGTXT" &&
      fields.type !== "AUDIO" &&
      (fieldname === undefined || fieldname === "option1")
    ) {
      if (fieldname === undefined) {
        fieldValue = fields["option1"] ? fields["option1"] : "";
        updatedValue = fieldValue;
      }

      delete errorsT["option1"];
      if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        formIsValid3 = false;
        errorsT["option1"] = "Please Input value in Option 1";
      }
    }

    //option2 validations
    if (
      fields.type !== "TEXT" &&
      fields.type !== "IMGTXT" &&
      fields.type !== "AUDIO" &&
      (fieldname === undefined || fieldname === "option2")
    ) {
      if (fieldname === undefined) {
        fieldValue = fields["option2"] ? fields["option2"] : "";
        updatedValue = fieldValue;
      }

      delete errorsT["option2"];
      if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        formIsValid3 = false;
        errorsT["option2"] = "Please Input value in Option 2";
      }
    }

    //option3 validations
    if (
      fields.type !== "TEXT" &&
      fields.type !== "IMGTXT" &&
      fields.type !== "AUDIO" &&
      (fieldname === undefined || fieldname === "option3")
    ) {
      if (fieldname === undefined) {
        fieldValue = fields["option3"] ? fields["option3"] : "";
        updatedValue = fieldValue;
      }

      delete errorsT["option3"];
      if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        formIsValid3 = false;
        errorsT["option3"] = "Please Input value in Option 3";
      }
    }

    //option4 validations
    if (
      fields.type !== "TEXT" &&
      fields.type !== "IMGTXT" &&
      fields.type !== "AUDIO" &&
      (fieldname === undefined || fieldname === "option4")
    ) {
      if (fieldname === undefined) {
        fieldValue = fields["option4"] ? fields["option4"] : "";
        updatedValue = fieldValue;
      }

      delete errorsT["option4"];
      if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        formIsValid3 = false;
        errorsT["option4"] = "Please Input value in Option 4";
      }
    }

    //correctAnswer validations
    if (
      fields.type !== "TEXT" &&
      fields.type !== "IMGTXT" &&
      fields.type !== "AUDIO" &&
      (fieldname === undefined || fieldname === "correctAnswer")
    ) {
      if (fieldname === undefined) {
        fieldValue = fields["correctAnswer"] ? fields["correctAnswer"] : "";
        updatedValue = fieldValue;
      }

      delete errorsT["correctAnswer"];
    }

    //type validations
    if (fieldname === undefined || fieldname === "type") {
      if (fieldname === undefined) {
        fieldValue = fields["type"] ? fields["type"] : "";
        updatedValue = fieldValue;
      }

      delete errorsT["type"];
      if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        formIsValid3 = false;
        errorsT["type"] = "Type is required";
      }
    }

    //complexity validations
    if (fieldname === undefined || fieldname === "complexity") {
      if (fieldname === undefined) {
        fieldValue = fields["complexity"] ? fields["complexity"] : "";
        updatedValue = fieldValue;
      }

      delete errorsT["complexity"];
      if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        formIsValid3 = false;
        errorsT["complexity"] = "Complexity is required";
      }
    }

    //skill validations
    if (fieldname === undefined || fieldname === "skill") {
      if (fieldname === undefined) {
        fieldValue = fields["skill"] ? fields["skill"] : "";
        updatedValue = fieldValue;
      }

      delete errorsT["skill"];
      if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        formIsValid3 = false;
        errorsT["skill"] = "Skills is required";
      }
    }

    //duration validations
    if (fieldname === undefined || fieldname === "duration") {
      if (fieldname === undefined) {
        fieldValue = fields["duration"] ? fields["duration"] : "";
        updatedValue = fieldValue;
      }

      delete errorsT["duration"];
      if (!(Array.isArray(updatedValue) ? updatedValue.length : updatedValue)) {
        formIsValid3 = false;
        errorsT["duration"] = "Duration is required";
      } else if (updatedValue <= 0) {
        formIsValid3 = false;
        errorsT["duration"] =
          "Duration value should be positive & greater than 0";
      }
    }
    setErrors({ ...errorsT });
    return { formIsValid3, updatedValue };
  };

  useEffect(() => {
    let optionArray = [];
    if (fields.option1) {
      optionArray.push({ id: 1, value: fields.option1, text: "Option 1" });
    }
    if (fields.option2) {
      optionArray.push({ id: 2, value: fields.option2, text: "Option 2" });
    }
    if (fields.option3) {
      optionArray.push({ id: 3, value: fields.option3, text: "Option 3" });
    }
    if (fields.option4) {
      optionArray.push({ id: 4, value: fields.option4, text: "Option 4" });
    }
    setAllOptions([...optionArray]);
  }, [fields.option1, fields.option2, fields.option3, fields.option4]);

  const handleChange = (field, e) => {
    const { updatedValue } = handleValidation(field, e);
    let fieldsT = fields;
    // if (field == "type") {
    //   setFields({...fields,correctanswer:[]});
    // } else
    if (field === "correctanswer") {
      fieldsT[field] = updatedValue.map((item) => {
        return item?.value;
      });
    } else if (field === "skill") {
      const itemName = [];
      const itemId = [];

      // fieldsT[field] =
      updatedValue.map((item) => { // eslint-disable-line
        if (item.name !== undefined && item.id !== undefined) {
          itemName.push(item.name);
          itemId.push(item.id);
        }
      });

      fieldsT[field] = itemName;
      fieldsT["skillId"] = itemId;
    } else {
      fieldsT[field] = updatedValue;
    }
    setFields({ ...fieldsT });
  };

  const makePost = () => {
    let fieldsT = {};
    Object.assign(fieldsT, fields);
    if (fieldsT["type"] === "AUDIO") {
      fieldsT["filename"] =
        moment(new Date()).format("YYYYMMDD-HHmmss") + "-audioQues";
    }
    let data = JSON.parse(localStorage.getItem("EditRowData"));

    if (data) {
      Object.assign(fieldsT, { ...fieldsT });
      if (
        data.complexity !== fieldsT.complexity ||
        data.skillId !== fieldsT.skillId ||
        data.type !== fieldsT.type
      ) {
        fetch(URLDATA.url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
            xcvtoken: localStorage.getItem("id"),
          },
          body: JSON.stringify({
            query: getQuestionDetails,
            variables: {
              where: {
                complexity: data.complexity,
                skillId: data.skillId,
                type: data.type,
                isActive: 1,
              },
            },
            context: { clientName: "server1" },
          }),
        })
          .then((res) => res.json())
          .then(() => {
            saveMutation({
              variables: {
                ...fieldsT,
                id: data.id,
                question: fields.question,
                duration: parseFloat(fields.duration * 60000),
                skill: fields.skill.toString(),
                skillId: fields.skillId && fields.skillId.toString(),
                correctanswer: fields.correctanswer.toString(),
                type: fields.type,
              },
            });
            setDialogOpen(true);
          })
          .catch((err) => {
            console.log("err --->", err);
          });
      } else {
        saveMutation({
          variables: {
            ...fieldsT,
            id: data.id,
            question: fields.question,
            duration: parseFloat(fields.duration * 60000),
            skill: fields.skill.toString(),
            skillId: fields.skillId && fields.skillId.toString(),
            correctanswer: fields.correctanswer.toString(),
            type: fields.type,
          },
        });
        setDialogOpen(true);
      }
    } else {
      saveMutation({
        variables: {
          input: fieldsT,
        },
      })
      setDialogOpen(true);
    }
  };

  const getDataNew = () => {
    if (fields.question)
      return fields.question
    else {
      if (editableData != null)
        return editableData.question
      else
        return ""
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const { formIsValid3 } = handleValidation();
    if (formIsValid3) {
      makePost();
    }
  };

  useEffect(() => {
    if (submitError) {
      setFormError(submitError.toString());
    }
  }, [submitError]);

  return (
    <>
      <Grid container xs={12} md={12} spacing={3}>
        <div className="inner-header">
          <div className="margin-auto" style={{ margin: "0px 38% auto" }}>
            <h4 className="page-title">
              {JSON.parse(localStorage.getItem("EditRowData")) ? "Edit" : "Add"}{" "}
              Question
            </h4>
          </div>
          <div>
            <Link className="navNames" to="/test-questions">
              <Button color="primary" variant="outlined">
                {" "}
                Back{" "}
              </Button>
            </Link>
          </div>
        </div>
      </Grid>
      <form>
        <div style={{ margin: "2%" }}>
          <Grid container xs={12} md={12} spacing={1}>
            <Grid item xs={12} md={6}>
              <Autocomplete
                style={{ padding: "1rem" }}
                value={
                  fields.type
                    ? typeOptions.find((item) => item.value === fields.type)
                    : null
                }
                options={typeOptions}
                getOptionLabel={(option) => (option.text ? option.text : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                    variant="outlined"
                    className="inputWidth"
                    id="type"
                    label="Type"
                  />
                )}
                onChange={(_event, newValue) => {
                  handleChange("type", newValue ? newValue.value : null);
                }}
              />
              <div style={{ color: "red" }}>{errors["type"]}</div>
            </Grid>
            <Grid item xs={12} md={12}>
              <label>Question</label>
              <CKEditor
                disabled={QuestionVideo}
                editor={ClassicEditor}
                data={getDataNew()}
                config={editorConfiguration}
                onChange={(_event, editor) => {
                  const data = editor.getData();
                  handleChange("question", data);
                }}
              />
              {/* </CKEditorContext> */}
              <div style={{ color: "red" }}>{errors["question"]}</div>
              <label>
                <input
                  type="checkbox"
                  onChange={() => toggleDisabled("question")}
                />
                play video
              </label>
            </Grid>
            {fields.type !== "TEXT" &&
              fields.type !== "IMGTXT" &&
              fields.type !== "AUDIO" && (
                <>
                  <Grid item xs={12} md={6}>
                    <label>Option 1</label>

                    <CKEditor
                      id="option12"
                      editor={ClassicEditor}
                      disabled={Option1Video}
                      data={fields ? fields.option1 : ""}
                      config={editorConfiguration}
                      onChange={(_event, editor) => {
                        const newdata = editor.getData();
                        handleChange("option1", newdata);
                      }}
                    />
                    <label>
                      <input
                        type="checkbox"
                        onChange={() => toggleDisabled("option1")}
                      />
                      play video
                    </label>
                    <div style={{ color: "red" }}>{errors["option1"]}</div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label>Option 2</label>
                    <CKEditor
                      id="option24"
                      editor={ClassicEditor}
                      disabled={Option2Video}
                      data={fields ? fields.option2 : ""}
                      config={editorConfiguration}
                      onChange={(_event, editor) => {
                        const getdata = editor.getData();
                        handleChange("option2", getdata);
                      }}
                    />
                    <label>
                      <input
                        type="checkbox"
                        onChange={() => toggleDisabled("option2")}
                      />
                      play video
                    </label>
                    <div style={{ color: "red" }}>{errors["option2"]}</div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label>Option 3</label>
                    <CKEditor
                      id="option3"
                      editor={ClassicEditor}
                      disabled={Option3Video}
                      data={fields ? fields.option3 : ""}
                      config={editorConfiguration}
                      onChange={(_event, editor) => {
                        const data = editor.getData();
                        handleChange("option3", data);
                      }}
                    />
                    <label id="ques4">
                      <input
                        id="ques4_"
                        type="checkbox"
                        onChange={() => toggleDisabled("option3")}
                      />
                      play video
                    </label>
                    <div style={{ color: "red" }}>{errors["option3"]}</div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label>Option 4</label>
                    <CKEditor
                      id="option4ssd"
                      editor={ClassicEditor}
                      disabled={Option4Video}
                      data={fields ? fields.option4 : ""}
                      config={editorConfiguration}
                      onChange={(_event, editor) => {
                        const data = editor.getData();
                        handleChange("option4", data);
                      }}
                    />
                    <label id="ques3">
                      <input
                        id="ques3_"
                        type="checkbox"
                        onChange={() => toggleDisabled("option4")}
                      />
                      play video
                    </label>
                    <div style={{ color: "red" }}>{errors["option4"]}</div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      multiple
                      className="inputWidth"
                      value={fields?.correctanswer.map((item) => {
                        return allOptions.find(
                          (option) => option?.value === item
                        );
                      })}
                      options={allOptions.map((option) => option)}
                      getOptionLabel={(item) => {
                        if (item?.value && item?.text) {
                          return item.text;
                        }
                      }}
                      renderOption={(propsss, option2) => (
                        <li {...propsss}>{option2.text}</li>
                      )}
                      renderInput={
                        (params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                            label="correctanswer (Multi-select)"
                            variant="outlined"
                          />
                        )
                        /*)*/
                      }
                      onChange={(_event, newValue) => {
                        handleChange("correctanswer", newValue);
                      }}
                    />
                  </Grid>
                </>
              )}
            <Grid item xs={12} md={6}>
              <Autocomplete
                className="inputWidth"
                value={
                  fields.complexity
                    ? complexityOptions.find(
                      (item) => item.value === fields.complexity
                    )
                    : null
                }
                options={complexityOptions}
                getOptionLabel={(option) => (option.text ? option.text : "")} //complexity
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                    label="Complexity"
                    variant="outlined"
                  />
                )}
                onChange={(_event, newValue) => {
                  handleChange("complexity", newValue ? newValue.value : null);
                }}
              />
              <div style={{ color: "red" }}>{errors["complexity"]}</div>
            </Grid>

            <Grid item xs={12} md={6}>
              <Autocomplete
                className="inputWidth"
                multiple
                // style={{ width: "198px" }}
                value={fields.skill.map((item) => {// eslint-disable-line
                  let optionValue = skillsData.find(
                    (skill) => skill.name === item
                  );
                  if (optionValue !== undefined) {
                    return optionValue;
                  }
                })}
                variant="filled"
                onChange={(_event, newValue) => {
                  handleChange("skill", newValue);
                }}
                filterOptions={(options, params) => {
                  return filter(options, params);
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={skillsData.map((option) => option)}
                getOptionLabel={(item) => {
                  if (item && item.name) {
                    return item.name;
                  }
                }}
                renderOption={(propss, option2) => (
                  <li {...propss}>{option2.name}</li>
                )}
                sx={{ width: 300 }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Skills (Multi-select)"
                  />
                )}
              />
              <div style={{ color: "red" }}>{errors["skill"]}</div>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                className="inputWidth"
                id="duration"
                label="Duration (in minutes)"
                type="number"
                value={fields.duration}
                style={{ width: 200 }}
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: null,
                  },
                }}
                onChange={(e) => {
                  handleChange("duration", e);
                }}
                onBlur={(e) => {
                  handleChange("duration", e);
                }}
                required
              />
              <div style={{ color: "red" }}>{errors["duration"]}</div>
            </Grid>

            <Grid item xs={12} id="quesgrid">
              <div style={{ color: "red" }} id="queserror">
                {formError}
              </div>
              <Button
                color="primary"
                variant="contained"
                disabled={loading || formProcessing}
                onClick={(e) => {
                  handleSubmit(e);
                }}
                id="saveButtonQues"
              >
                {(loading || formProcessing) && (
                  <span style={{ color: "white" }} id="quesspan">
                    <i
                      id="quesi"
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />{" "}
                    Please Wait...
                  </span>
                )}
                {!(loading || formProcessing) && (
                  <span id="quesspan2">
                    {localStorage.getItem("EditRowData") ? "Update" : "Submit"}
                  </span>
                )}
              </Button>
              <Dialog
                id="quesdialog1"
                open={dialogOpen}
                onClose={handleDialogClose}
                disableEscapeKeyDown={true}
              >
                <DialogContent id="quesdialogcontext1">
                  <div style={{ width: "300px" }}>
                    <DialogContentText>{"Success!!"}</DialogContentText>
                  </div>
                </DialogContent>
                <DialogActions id="quesdialogaction1">
                  <Button onClick={handleDialogClose} color="primary" autoFocus>
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </div>
      </form>
    </>
  );
};
QuestionsInner.propTypes = {
  classes: PropTypes.object.isRequired,
};
const CompWithStyleInner = withStyles(styles, { withTheme: true })(
  QuestionsInner
);
function Questions() {
  return <CompWithStyleInner />;
}
export default Questions;
