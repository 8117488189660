export const formatSSN = (field, value) => {
  if (!value) return value;
  let currentValue = value ? value.replace(/-|s/g, "") : "";
  const cvLength = currentValue.length;
  if (cvLength > 3 && cvLength < 6) {
    currentValue = `${currentValue.slice(0, 3)}-${currentValue.slice(3, 5)}`;
  } else
    currentValue = `${currentValue.slice(0, 3)}-${currentValue.slice(
      3,
      5
    )}-${currentValue.slice(5)}`;

  if (currentValue.length >= 12) {
    currentValue = currentValue.substr(0, 11);
  }
  return currentValue;
};

export const IsValidEmail = (value) => {
  return new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@([A-Za-z0-9_\-])+(\.([A-Za-z]{2,})|\.([A-Za-z]{2,})\.([A-Za-z]{2,}))$/ // eslint-disable-line
  ).test(value);
};

export const IsValidSSN = (value) => {
  return new RegExp(/^(\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)$/).test(value);
};

export const IsValidNum = (value) => {
  return new RegExp("^[0-9]+$").test(value);
};

export const urlPatternValidation = (URL) => {
  const regex = new RegExp("^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$") // eslint-disable-line
  return regex.test(URL);
};

export const urlWebsitePatternValidation = (URL) => {
  const regex = new RegExp("^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$") // eslint-disable-line
  return regex.test(URL);
};

export const isValidMobileNumber = (num) => {
  const regex = new RegExp(/^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/) // eslint-disable-line
  return regex.test(num);
};

export const removeEmptySpaces = stringVal => {
  return /\s/g.test(stringVal);
};

export const isValidPhoneNumber = (num) => {
  const regexArr = [
    ["^[2-9][0-9]{9}$", "Please Enter a valid US Phone Number"],
    ["^[6-9][0-9]{9}$", "Please Enter a valid India Phone Number"],
    ["^[0-9]{7,15}$", "Number length should be be between 7 to 15 digits"],
  ];
  const numOnly = num
    .replace(/^[\S]+ /, "")
    .replace(/[\s()-]/g, "")
    .replace("+", "");
  if (1 == 0) console.log("dummy");
  else if (num.indexOf("+1 ") === 0 && !new RegExp(regexArr[0][0]).test(numOnly))
    //US No
    return false;
  else if (
    num.indexOf("+91 ") === 0 &&
    !new RegExp(regexArr[1][0]).test(numOnly)
  )
    //India No
    return false;
  else if (!new RegExp(regexArr[2][0]).test(numOnly))
    //any no
    return false;
  else return true;
};