import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { gql, useMutation } from "@apollo/client";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {
  DataGrid as DataGridElement,
  GridToolbar,
} from "@material-ui/data-grid";
import Edit from "@material-ui/icons/Edit";
import TrashIcon from "@material-ui/icons/Delete";
import swal from "sweetalert";
import Button from "@mui/material/Button";
import URLDATA, { authHeader } from "../config";
import { useHistory } from "react-router";
import { getAllTestInfo } from "../query.js";
import {
  usePersistedState,
  clearOnReloadPage,
} from "../utils/usePersistedState";
import { customNoRowsOverlay } from "../utils/overlay";

const styles = () => ({});

const DataGrid = (props) => {
  const [page, setPage] = usePersistedState("Page", 0, "createTest");
  const [pageSize, setPageSize] = usePersistedState(
    "PageSize",
    10,
    "createTest"
  );
  const [rows, setRows] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [prevPage, setPrevPage] = useState(0);

  const history = useHistory();

  const fetchAllTest = () => {
    fetch(URLDATA.url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
        xcvtoken: localStorage.getItem("id"),
      },
      body: JSON.stringify({
        query: getAllTestInfo,
        variables: {},
        context: { clientName: "server1" },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        localStorage.removeItem("EditRowData");
        if (res.errors) {
          const errText = JSON.parse(res.errors[0].message);
          throw new Error(errText.error);
        }
        setRows(res.data.allTests.Tests.filter((data) => data.isactive === 1));
        setTotalCount(
          res.data.allTests.Tests
            ? res.data.allTests.Tests?.filter((data) => data.isactive === 1)
              .length
            : 1000
        );
        setLoading(false);
      })
      .catch((err) => {
        swal(err.message);
        localStorage.clear();
        history.push("/login");
      });
  };
  useEffect(() => {
    if (filters.value !== undefined && filters.value !== "") {
      setPrevPage(page);
      setPage(0);
    } else if (prevPage !== 0) setPage(prevPage);
  }, [filters]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => setPage(0), [pageSize]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchAllTest();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const handlerPageChange = (param) => {
    setPage(param);
  };
  const handlerPageSizeChange = async (params) => {
    await setPageSize(params);
  };

  let router = useHistory();
  let columns = [
    { field: "code", headerName: "Code", width: 300 },
    {
      field: "numberofquestions",
      headerName: "No. of Questions",
      width: 200,
      valueGetter: (params) => {
        let result = [];
        let numberofquestions = 0;
        if (params.row.testquestionmaps_ibfk_1) {
          if (params.row?.testquestionmaps_ibfk_1?.Testquestionmaps) {
            params.row?.testquestionmaps_ibfk_1?.Testquestionmaps?.map(
              (data) => {// eslint-disable-line
                numberofquestions += data.numberofquestions;
              }
            );
            result.push(numberofquestions);
          }
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
    },
    { field: "duration", headerName: "Duration", width: 150 },
    { field: "description", headerName: "Descriptions", width: 450 },
    { field: "action", disableExport: true, headerName: "Actions", width: 200 },
  ].filter((item) => {
    return item.field !== "action";
  });

  columns = [
    ...columns,
    {
      disableExport: true,
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <strong>
          <Edit
            onClick={() => {
              localStorage.setItem(
                "EditRowData",
                JSON.stringify({ ...params.row })
              );
              router.push({ pathname: "/edit-test" });
            }}
            style={{ marginInline: "4px", cursor: "pointer" }}
          />
          <TrashIcon
            onClick={() => {
              console.log(params.row);
              swal({
                title: "Are you sure?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  props.deleteHandler({ ...params.row, id: params.row.id });
                  swal("Test has been deleted!", {
                    icon: "success",
                  }).then(() => {
                    fetchAllTest();
                  });
                }
              });
            }}
            style={{ marginInline: "4px", cursor: "pointer" }}
          />
        </strong>
      ),
    },
  ];

  return (
    <>
      <Grid container xs={12} style={{ height: "86vh", marginTop: "2rem" }}>
        <DataGridElement
          columns={columns}
          rows={rows}
          rowCount={totalCount}
          rowsPerPageOptions={[10, 15, 20, 25, 50, 75, 100]}
          pageSize={pageSize}
          page={page}
          density="compact"
          components={{
            Toolbar: GridToolbar,
            NoRowsOverlay: customNoRowsOverlay,
          }}
          onFilterModelChange={(model) => setFilters(model?.items[0])}
          onPageChange={handlerPageChange}
          onPageSizeChange={handlerPageSizeChange}
          loading={loading}
        />
      </Grid>
    </>
  );
};

const TestsInner = () => {
  const [fields, setFields] = useState({});
  const [formError, setFormError] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const gqlMutation = (function () {
    let code = `  
    mutation($code:String!,$createdat:Date!,$description:String!,$duration:String!,$id:Float,$testinstructions:String,$updatedat:Date!,$isactive:Float){saveTests(obj:{code:$code,createdat:$createdat,duration:$duration,updatedat:$updatedat,testinstructions:$testinstructions,description:$description,id:$id,isactive:$isactive}){
      code
      createdat
      description
      duration
      testinstructions
      updatedat
      id
      isactive
  }
  }`;
    return gql`
      ${code}
    `;
  })();
  const [saveMutation, { error: submitError }] = useMutation(gqlMutation, {
    context: authHeader(),
  });
  useEffect(() => {
    clearOnReloadPage();
    localStorage.setItem("RowPage", "createTest");
    let fieldsT = {};
    let data = JSON.parse(localStorage.getItem("EditRowData"));
    if (data) {
      Object.entries(data).forEach(() => {
        setFields({ ...fieldsT });
      });
    }
  }, []);

  useEffect(() => {
    if (submitError) {
      setFormError(submitError.toString());
    }
  }, [submitError]);

  const deletePost = (field) => {
    let fieldsT = {};
    Object.assign(fieldsT, {
      code: field.code,
      createdat: new Date(),
      description: field.description || " ",
      duration: field.duration,
      id: field.id,
      testinstructions: " ",
      updatedat: new Date(),
      isactive: 0,
    });
    saveMutation({
      variables: {
        ...fieldsT,
      },
      context: authHeader(),
    });
  };

  const deleteHandler = (item) => {
    deletePost({ id: item.id, ...item });
  };
  return (
    <Grid>
      <Grid container xs={12} md={12} spacing={3}>
        <div className="inner-header">
          <div>
            <h4 className="page-title">Manage Tests</h4>
          </div>
          <div>
            <Link className="navNames" to="/add-test">
              <Button color="primary" variant="outlined">
                {" "}
                Add New Test
              </Button>
            </Link>
          </div>
        </div>
      </Grid>

      <form id="testform">
        <div id="testdiv">
          <Grid
            style={{ padding: "0 15px", margin: "0px" }}
            container
            xs={12}
            md={12}
          >
            <Grid item xs={12} md={6} id="testgrid">
              <div style={{ color: "red" }} id="testerror">
                {formError}
              </div>

              <Dialog
                id="testdialog"
                open={dialogOpen}
                onClose={handleDialogClose}
                disableEscapeKeyDown={true}
                aria-describedby="alert-dialog-description"
              >
                <DialogContent id="dialogcontent">
                  <div style={{ width: "300px" }}>
                    <DialogContentText id="alert-dialog-description">
                      Success!!
                    </DialogContentText>
                  </div>
                </DialogContent>
                <DialogActions id="testactions">
                  <Button onClick={handleDialogClose} color="primary" autoFocus>
                    {"Ok"}
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <DataGrid deleteHandler={deleteHandler} parentState={fields} />
          </Grid>
        </div>
      </form>
    </Grid>
  );
};
TestsInner.propTypes = {
  classes: PropTypes.object.isRequired,
};
const CompWithStyleInner = withStyles(styles, { withTheme: true })(TestsInner);
function TestList() {
  return <CompWithStyleInner />;
}
export default TestList;
