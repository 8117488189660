import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import { gql, useMutation } from "@apollo/client";
import { getQuestionDetails } from "../query";
import {
  DataGrid as DataGridElement,
  GridToolbar,
} from "@material-ui/data-grid";
import Edit from "@material-ui/icons/Edit";
import TrashIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { customNoRowsOverlay } from "../utils/overlay";
import URLDATA, { authHeader } from "../config";
import {
  usePersistedState,
  clearOnReloadPage,
} from "../utils/usePersistedState";

const styles = () => ({});

const DataGrid = (props) => {
  const [page, setPage] = usePersistedState("Page", 0, "setQuestion");
  const [pageSize, setPageSize] = usePersistedState(
    "PageSize",
    10,
    "setQuestion"
  );
  const [rows, setRows] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [filters, setFilters] = useState({});
  const [prevPage, setPrevPage] = useState(0);

  const stripHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };
  useEffect(() => {
    if (filters.value !== undefined && filters.value !== "") {
      setPrevPage(page);
      setPage(0);
    } else if (prevPage !== 0) setPage(prevPage);
  }, [filters]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setColumns(gridcolumns);
    clearOnReloadPage();
    localStorage.setItem("RowPage", "setQuestion");
    getUpdatedList();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const getUpdatedList = () => {
    setLoading(true);
    fetch(URLDATA.url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
        xcvtoken: localStorage.getItem("id"),
      },
      body: JSON.stringify({
        query: `
        query{
          allQuestions{
            totalCount
            Questions {
              complexity
              corretanswer
              createdat
              id
              option1
              option2
              option3
              option4
              question
              skill
              skillid
              type
              updatedat
              isactive
              duration
            }
          }
        }
      `,
        variables: {},
        context: { clientName: "server1" },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        localStorage.removeItem("EditRowData");

        let newDataToShow = res.data?.allQuestions?.Questions?.filter(
          (data) => data.isactive === 1
        );
        newDataToShow = newDataToShow.map((item) => {
          let newItemToShow = { ...item };
          newItemToShow.question = stripHtml(newItemToShow.question);
          newItemToShow.duration =
            (newItemToShow.duration / 60000).toFixed(2) + " min";
          return newItemToShow;
        });
        setRows(newDataToShow);
        setTotalCount(
          res.data?.allQuestions
            ? res.data?.allQuestions?.Questions.filter(
              (data) => data.isactive === 1
            ).length
            : 1000
        );
        setLoading(false);
      });
  };

  useEffect(() => setPage(0), [pageSize]);// eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (param) => {
    setPage(param);
  };
  const handlePageSizeChange = async (params) => {
    setPageSize(params);
  };

  let router = useHistory();

  let gridcolumns = [
    { field: "question", headerName: "Question", width: 750 },
    { field: "duration", headerName: "Duration", width: 150 },
    { field: "skill", headerName: "Skill", width: 150 },
    { field: "type", headerName: "Type", width: 150 },
    { field: "complexity", headerName: "Complexity", width: 150 },
    { field: "action", disableExport: true, headerName: "Actions", width: 60 },
  ].filter((item) => {
    return item.field !== "action";
  });

  gridcolumns = [
    ...gridcolumns,
    {
      disableExport: true,
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <strong>
          <Edit
            onClick={() => {
              localStorage.setItem("EditRowData", JSON.stringify(params.row));
              router.push({ pathname: "/edit-question" });
            }}
            style={{ marginInline: "4px", cursor: "pointer" }}
          />
          <TrashIcon
            onClick={
              // handleClickOpen
              () => {
                swal({
                  title: "Are you sure?",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    props.deleteHandler({
                      id: params.row.id,
                    });
                    swal("Question has been deleted!", {
                      icon: "success",
                    }).then(() => {
                      getUpdatedList();
                    });
                  }
                });
              }
            }
            style={{ marginInline: "4px", cursor: "pointer" }}
          />
        </strong>
      ),
    },
  ];

  return (
    <>
      <Grid
        container
        // xs={12} md={12}
        spacing={3}
      >
        <div className="inner-header">
          <div>
            <h4 className="page-title">Manage Questions</h4>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "20px" }}>
              <Link className="navNames" to="/import-question">
                <Button color="primary" variant="outlined">
                  {" "}
                  Import Questions
                </Button>
              </Link>
            </div>
            <Link className="navNames" to="/add-question">
              <Button color="primary" variant="outlined">
                {" "}
                Add Questions
              </Button>
            </Link>
          </div>
        </div>
      </Grid>
      <Grid
        container
        // xs={12}
        style={{ display: "block", height: "89vh" }}
      >
        <DataGridElement
          columns={columns}
          rows={rows}
          rowCount={totalCount}
          rowsPerPageOptions={[10, 15, 20, 25, 50, 75, 100]}
          pageSize={pageSize}
          page={page}
          onFilterModelChange={(model) => setFilters(model?.items[0])}
          density="compact"
          components={{
            Toolbar: GridToolbar,
            NoRowsOverlay: customNoRowsOverlay,
          }}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          loading={loading}
        />
      </Grid>
    </>
  );
};

const QuestionListInner = () => {
  const [fields, setFields] = React.useState({});
  const gqlMutation = (function () {
    let code = ` mutation($corretanswer:String,$id:Float,$complexity:String,$createdat:Date!,$option1:String,$option2:String,$option3:String,$option4:String,$question:String, $skill:String,$skillId:String,$type:String,$updatedat:Date!,$duration:Float,$isactive:Float) {
      saveQuestions(obj: {id:$id,complexity: $complexity, corretanswer: $corretanswer, createdat: $createdat, option1: $option1, option2: $option2, option3: $option3, option4: $option4, question: $question, skill: $skill,skillId:$skillId, type: $type, updatedat: $updatedat,duration:$duration,isactive:$isactive}) {
        complexity
        corretanswer
        createdat
        id
        option1
        option2
        option3
        option4
        question
        skill
        skillId
        type
        updatedat
        isactive
        }
        }
    `;
    return gql`
      ${code}
    `;
  })();
  const [saveMutation] = useMutation(gqlMutation, {
    // variables: {},
    context: authHeader(),
  });

  useEffect(() => {
    let fieldsg = {};
    let editrowdata = JSON.parse(localStorage.getItem("EditRowData"));
    if (editrowdata) {
      Object.entries(editrowdata).forEach(() => {
        setFields({ ...fieldsg });
      });
    }
  }, []);

  const deletePost = (field) => {
    let fieldsT = {};
    Object.assign(fieldsT, {
      id: field.id,
      question: field.question,
      corretanswer: field.corretanswer,
      option1: field.option1,
      option2: field.option2,
      option3: field.option3,
      option4: field.option4,
      complexity: field.complexity,
      skill: field.skill,
      skillId: field.skillId,
      type: field.type,
      duration: field.duration,
      isactive: 0,
      createdat: new Date(),
      updatedat: new Date(),
    });

    fetch(URLDATA.url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
        xcvtoken: localStorage.getItem("id"),
      },
      body: JSON.stringify({
        query: getQuestionDetails,
        variables: {
          where: {
            complexity: field.complexity,
            skillId: field.skillId,
            type: field.type,
            isActive: 1,
          },
        },
        context: { clientName: "server1" },
      }),
    })
      .then((res) => res.json())
      .then(() => {
        saveMutation({
          variables: {
            id: field.id,
            ...fieldsT,
          },
        });
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };

  const deleteHandler = (item) => {
    deletePost({ id: item.id, ...item });
  };
  return (
    <form>
      <div style={{ margin: "2%" }}>
        <Grid container spacing={4}>
          <DataGrid deleteHandler={deleteHandler} parentState={fields} />
        </Grid>
      </div>
    </form>
  );
};
QuestionListInner.propTypes = {
  classes: PropTypes.object.isRequired,
};
const CompWithStyleInner = withStyles(styles, { withTheme: true })(
  QuestionListInner
);
function QuestionsList() {
  return <CompWithStyleInner />;
}
export default QuestionsList;
