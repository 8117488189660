import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import Edit from "@material-ui/icons/Edit";
import TrashIcon from "@material-ui/icons/Delete";
import swal from "sweetalert";
import Button from "@mui/material/Button";
import URLDATA, { authHeader } from "../config";
import { useHistory } from "react-router";
import { getAllCollages } from "../query.js";
import { withStyles } from "@material-ui/core/styles";
import { gql, useMutation } from "@apollo/client";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {
  DataGrid as DataGridElement,
  GridToolbar,
} from "@material-ui/data-grid";
import {
  usePersistedState,
  clearOnReloadPage,
} from "../utils/usePersistedState";
import { customNoRowsOverlay } from "../utils/overlay";

const styles = () => ({});

const DataGrid = (props) => {
  const [page, setPage] = usePersistedState("Page", 0, "college");
  const [pageSize, setPageSize] = usePersistedState("PageSize", 10, "college");
  const [rows, setRows] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [prevPage, setPrevPage] = useState(0);

  const fetchCollegeList = () => {
    fetch(URLDATA.url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
        xcvtoken: localStorage.getItem("id"),
      },
      body: JSON.stringify({
        query: getAllCollages,
        variables: {},
        context: { clientName: "server1" },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        localStorage.removeItem("EditRowData");
        setRows(
          res.data.allCollages.Collages.filter((data) => data.active === 1)
        );
        setTotalCount(
          res.data.allCollages.Collages
            ? res.data.allCollages.Collages?.filter((data) => data.active === 1)
              .length
            : 1000
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    if (filters.value !== undefined && filters.value !== "") {
      setPrevPage(page);
      setPage(0);
    } else if (prevPage !== 0) setPage(prevPage);
  }, [filters]);// eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    clearOnReloadPage();
    localStorage.setItem("RowPage", "college");
    fetchCollegeList();
  }, []);
  useEffect(() => {
    setPage(0);
  }, [pageSize]);// eslint-disable-line react-hooks/exhaustive-deps
  const handlePageChange = (param) => {
    setPage(param);
  };
  const handlePageSizeChange = async (params) => {
    setPageSize(params);
  };

  let router = useHistory();
  let columns = [
    { field: "collagename", headerName: "Collage Name", width: 200 },
    { field: "location", headerName: "Location", width: 150 },
    { field: "address", headerName: "Address", width: 150 },
    { field: "website", headerName: "Website", width: 150 },
    { field: "contactperson", headerName: "Contact Person", width: 200 },
    {
      field: "emailid",
      headerName: "Email",
      width: 200,
      renderCell: (params) => {
        return (
          <a
            style={{ textDecoration: "none" }}
            href={`mailto: ${params.row.emailid}`}
          >
            {params.row.emailid}
          </a>
        );
      },
    },

    { field: "phonenumber", headerName: "Phone Number", width: 200 },
    { field: "mobilenumber", headerName: "Mobile Number", width: 200 },
    { field: "otherdetails", headerName: "Other Details", width: 170 },
    { field: "rating", headerName: "Rating", width: 120 },
    {
      field: "action",
      disableExports: true,
      headerName: "Actions",
      width: 150,
    },
  ].filter((item) => {
    return item.field !== "action";
  });

  columns = [
    ...columns,
    {
      disableExports: true,
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <strong>
          <Edit
            onClick={() => {
              localStorage.setItem(
                "EditRowData",
                JSON.stringify(params.row)
                // params.row.skill: [params.row.skill]
              );
              router.push({ pathname: "/edit-college" });
            }}
            style={{ marginInline: "4px", cursor: "pointer" }}
          />

          <TrashIcon
            onClick={
              // handleClickOpen
              () => {
                let uniqueId = params.row.id;
                let uniqueRowData = rows.find((row) => row.id === uniqueId);
                swal({
                  title: "Are you sure?",
                  // text: "Once deleted, you will not be able to recover this imaginary file!",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    props.deleteHandler({
                      ...uniqueRowData,
                      id: params.row.id,
                    });
                    //   getUpdatedList()
                    swal("College has been deleted!", {
                      icon: "success",
                    });
                    fetchCollegeList();
                  }
                });
              }
            }
            style={{ marginInline: "4px", cursor: "pointer" }}
          />
        </strong>
      ),
    },
  ];

  return (
    <Grid
      container
      id="ele1"
      xs={12}
      style={{ height: "85vh", marginTop: "2rem" }}
    >
      <DataGridElement
        columns={columns}
        rows={rows}
        rowCount={totalCount}
        rowsPerPageOptions={[10, 15, 20, 25, 50, 75, 100]}
        pageSize={pageSize}
        page={page}
        density="compact"
        components={{
          Toolbar: GridToolbar,
          NoRowsOverlay: customNoRowsOverlay,
        }}
        onFilterModelChange={(model) => setFilters(model?.items[0])}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        loading={loading}
      />
    </Grid>
  );
};

const TestsInner = () => {
  const [fields, setFields] = useState({});
  const [formError, setFormError] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const gqlMutation2 = (function () {
    let code = `  
    mutation(
        $id: Float,
      $active: Float,
      $address: String,
      $collagename: String,
      $contactperson: String,
      $createdby:Float,
      $createdon: Date!,
      $emailid: String,
      $location: String,
      $mobilenumber: String,
      $otherdetails: String,
      $phonenumber: String,
      $rating: Float,  
      $updatedby: Float,
      $updatedon: Date!,
      $website: String) {
            saveCollages(obj: {
      id:$id,          
      active:$active,
      address:$address,
      collagename:$collagename,
      contactperson:$contactperson,
      createdon:$createdon,
      createdby:$createdby,        
      emailid:$emailid,
      location: $location,
      mobilenumber: $mobilenumber,
      otherdetails: $otherdetails,
      phonenumber: $phonenumber,
      rating: $rating,         
      updatedby:$updatedby,
      updatedon:$updatedon,        
      website:$website
            }) {
      id
      active
      address
      collagename
      contactperson
      createdon
      createdby
      emailid
      location
      mobilenumber
      otherdetails
      phonenumber
      rating
      updatedby
      updatedon    
      website
        }
      }`;
    return gql`
      ${code}
    `;
  })();
  const [saveMutation, { error: submitError }] = useMutation(gqlMutation2, {
    context: authHeader(),
  });
  useEffect(() => {
    let fieldsS = {};
    let rowdata = JSON.parse(localStorage.getItem("EditRowData"));
    if (rowdata) {
      Object.entries(rowdata).forEach(() => {
        setFields({ ...fieldsS });
      });
    }
  }, []);

  useEffect(() => {
    if (submitError) {
      setFormError(submitError.toString());
    }
  }, [submitError]);

  const deletePost = (field) => {
    let fieldsT = {};
    Object.assign(fieldsT, {
      address: field.address,
      collagename: field.collagename,
      contactperson: field.contactperson,
      createdby: field.createdby,
      createdon: new Date(),
      location: field.location,
      emailid: field.skillId,
      mobilenumber: field.mobilenumber,
      otherdetails: field.otherdetails,
      phonenumber: field.phonenumber,
      rating: field.rating,
      updatedby: field.updatedby,
      updatedon: new Date(),
      website: field.website,
      id: field.id,
      active: 0,
    });
    saveMutation({
      variables: {
        ...fieldsT,
      },
      context: authHeader(),
    });
  };

  const deleteHandler = (item) => {
    deletePost({ id: item.id, ...item });
  };

  return (
    <Grid>
      <Grid container xs={12} md={12} spacing={3}>
        <div className="inner-header">
          <div>
            <h4 className="page-title">Manage College</h4>
          </div>
          <div>
            <Link className="navNames" to="/add-College">
              <Button color="primary" variant="outlined">
                {" "}
                Add New College
              </Button>
            </Link>
          </div>
        </div>
      </Grid>

      <form id="maineform">
        <div>
          <Grid
            style={{ padding: "0 15px", margin: "0px" }}
            container
            xs={12}
            md={12}
            spacing={3}
          >
            <Grid item xs={12} md={6}>
              <div style={{ color: "red" }}>{formError}</div>

              <Dialog
                id="college"
                open={dialogOpen}
                onClose={handleDialogClose}
                disableEscapeKeyDown={true}
                aria-describedby="alert-dialog-description"
              >
                <DialogContent id="collegecontent">
                  <div style={{ width: "300px" }}>
                    <DialogContentText id="alert-dialog-description">
                      {"Success!!"}
                    </DialogContentText>
                  </div>
                </DialogContent>
                <DialogActions id="collegeactions">
                  <Button onClick={handleDialogClose} color="primary" autoFocus>
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <DataGrid deleteHandler={deleteHandler} parentState={fields} />
          </Grid>
        </div>
      </form>
    </Grid>
  );
};
TestsInner.propTypes = {
  classes: PropTypes.object.isRequired,
};
const CompWithStyleInner = withStyles(styles, { withTheme: true })(TestsInner);
function College() {
  return <CompWithStyleInner />;
}
export default College;
