import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router";
import swal from "sweetalert";
import { TextField } from "@material-ui/core";
import Button from "@mui/material/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { gql, useMutation } from "@apollo/client";
import URLDATA, { authHeader } from "../config";
import Autocomplete from "@mui/material/Autocomplete";
import { getTestDetails } from "../query";

import "../All.css";
import { Link } from "react-router-dom";
import _ from "lodash";

function AddTest() {
  let [description, setDescription] = useState(""),
    [fields, setFields] = useState({}),
    [errors, setErrors] = useState({}),
    [formError, setFormError] = useState(""),
    [testCode, setTestCode] = useState(""),
    [duration, setDuration] = useState(""),
    [dialogOpen, setDialogOpen] = useState(false),
    [formProcessing, setFormProcessing] = useState(false),
    [allSkills, setAllSkills] = useState([]),
    [skillTypes, setSkillTypes] = useState([]),
    [selectSkillValue, setSelectSkillValue] = useState({}),
    [selectSkillTypesValue, setSelectSkillTypesValue] = useState({}),
    [fetchAvailableQuestions, setFetchAvailableQuestions] = useState([]),
    testinstructions =
      "Total number of questions:.Time alloted:.No negative marking.Make sure you do not refresh the page.Click the 'I am done' button given in the bottom of the page to submit your answers and end the test.Test would be auto submitted once the time is Expired",
    [createdTestId, setCreatedTestId] = useState(),
    [skillsLoaded, setSkillsLoaded] = useState(false);

  let router = useHistory();

  const gqlMutation = (function () {
    let code = ` 
        mutation($code: String!,$createdat: Date!,$description: String,$duration: String!,$testinstructions: String,$updatedat: Date!,$id:Float){saveTests(obj:{code:$code,createdat:$createdat,duration:$duration,updatedat:$updatedat,description:$description,testinstructions:$testinstructions,id:$id}){
          id
      }
      }`;
    return gql`
      ${code}
    `;
  })();

  const fetchSkills = (function () {
    let code = `mutation{QuestionsGetDistinctTestSkills}`;
    return gql`
      ${code}
    `;
  })();

  const minsToHHMMSS = (mins) => {
    let mins_num = parseFloat(mins, 10); // don't forget the second param
    let hours = Math.floor(mins_num / 60);
    let minutes = Math.floor(mins_num - (hours * 3600) / 60);
    let seconds = Math.floor(mins_num * 60 - hours * 3600 - minutes * 60);

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  };
  const testQuestionsMapList = () => {
    let finalArray = [];
    let skillList = Object.keys(fields);
    skillList.forEach((skill) => {
      let complexityValue = fields[skill].inputValues;

      if (!!complexityValue) {
        let complexityKeys = Object.keys(complexityValue);
        complexityKeys.forEach((value) => {
          let complexityObject = {};
          if (
            complexityValue[value] &&
            complexityValue[value] !== null &&
            complexityValue[value] !== 0
          ) {
            complexityObject.testid = createdTestId;
            complexityObject.skill = skill.split("_")[0];
            complexityObject.skillid = fields[skill].skillId;
            complexityObject.type = skill.split("_")[1];
            complexityObject.complexity = value;
            complexityObject.numberofquestions = parseInt(
              complexityValue[value]
            );
            complexityObject.createdat = new Date();
            complexityObject.updatedat = new Date();
            finalArray.push(complexityObject);
          }
        });
      }
    });
    return finalArray;
  };
  const [saveMutation, { data: returnData, loading, error: submitError }] =
    useMutation(gqlMutation, {
      variables: {
        code: testCode,
        createdat: new Date(),
        description: description,
        duration: minsToHHMMSS(duration),
        testinstructions: testinstructions,
        updatedat: new Date(),
      },
      onCompleted: submitCompleted,
      context: authHeader(),
    });

  const [_fetchSkill, { data: _returnData }] =
    useMutation(fetchSkills, {
      context: authHeader(),
    });

  useEffect(() => {
    _fetchSkill();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!!_returnData) {
      const cloneReturnData = _.cloneDeep(_returnData);
      filterUniqueSkills(cloneReturnData.QuestionsGetDistinctTestSkills);
      setFetchAvailableQuestions(
        cloneReturnData.QuestionsGetDistinctTestSkills
      );
      setSkillsLoaded(true);
    }
  }, [_returnData]);

  const filterUniqueSkills = (data) => {
    let distinct = []
    distinct = Array.from(new Set(data.map(s => s.skillId)))
      .map(skillId => {
        return {
          skillId: skillId,
          label: data.find(x => x.skillId === skillId).skill,
          value: data.find(x => x.skillId === skillId).skill
        }
      });
    setAllSkills(distinct);
  };

  const formatSkillData = (_data) => {
    const cloneData = _.cloneDeep(_data);
    return cloneData.reduce((acc, item) => {
      if (acc[`${item.skill}_${item.type}`] === undefined) {
        acc[`${item.skill}_${item.type}`] = item;
        acc[`${item.skill}_${item.type}`].complexity = {
          [item.complexity]: item.count,
        };
        acc[`${item.skill}_${item.type}`].label = item.skill;
        acc[`${item.skill}_${item.type}`].type = item.type;

        return acc;
      }

      acc[`${item.skill}_${item.type}`].complexity = {
        ...acc[`${item.skill}_${item.type}`].complexity,
        [item.complexity]: item.count,
      };
      acc[`${item.skill}_${item.type}`].label = item.skill;
      acc[`${item.skill}_${item.type}`].type = item.type;

      return acc;
    }, {});
  };

  useEffect(() => {
    if (returnData !== undefined) {
      return setCreatedTestId(returnData.saveTests.id);
    }
  }, [returnData]);

  const gqlMutation1 = (function () {
    let code = ` 
        mutation TestquestionmapsAddSkillsAndComplexity($complexity:JSON,$testid:Int){
          TestquestionmapsAddSkillsAndComplexity(complexity:$complexity,testid:$testid)
        }`;
    return gql`
      ${code}
    `;
  })();

  const [
    saveMutationNew,
    { loading: loadingNew },
  ] = useMutation(gqlMutation1, {
    variables: {
      complexity: [testQuestionsMapList()],
    },
    onCompleted: submitCompleted,
    context: authHeader(),
  });

  useEffect(() => {
    createdTestId !== undefined &&
      saveMutationNew({
        variables: {
          complexity: [...testQuestionsMapList()],
          testid: createdTestId,
        },
        context: authHeader(),
      });
  }, [createdTestId]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let EDIT_ROW = JSON.parse(localStorage.getItem("EditRowData"));

    if (EDIT_ROW && skillsLoaded && _returnData) {
      const cloneReturnData = _.cloneDeep(_returnData);

      const _filterData = formatSkillData(
        cloneReturnData.QuestionsGetDistinctTestSkills
      );
      setDescription(EDIT_ROW.description);
      setDuration(HHMMSSTomins(EDIT_ROW.duration));
      setTestCode(EDIT_ROW.code);
      const _fields = _.cloneDeep(_filterData);
      const testData =
        EDIT_ROW.testquestionmaps_ibfk_1 &&
        EDIT_ROW.testquestionmaps_ibfk_1.Testquestionmaps;
      let separateNullKeys = {};

      for (const key in _fields) {
        testData &&
          testData.map((item) => {// eslint-disable-line
            if (
              _fields[key].skill === item.skill &&
              _fields[key].type === item.type &&
              item.numberofquestions != null
            ) {
              _fields[key].inputValues = {
                ..._fields[key].inputValues,
                [item.complexity]: item.numberofquestions,
              };
            }
          });
      }

      for (const key in _fields) {
        if (!!_fields[key].inputValues) {
          separateNullKeys = {
            ...separateNullKeys,
            [key]: _fields[key],
          };
        }
      }

      setFields(separateNullKeys);
    }
  }, [skillsLoaded, _returnData]);

  useEffect(() => {
    if (submitError) {
      setFormError(submitError.toString());
    }
  }, [submitError]);

  const makePost = () => {
    let fieldsT = {};
    Object.assign(fieldsT, fields);
    let data = JSON.parse(localStorage.getItem("EditRowData"));
    if (data) {
      Object.assign(fieldsT, { ...fieldsT });
      saveMutation({
        variables: {
          ...fieldsT,
          id: data.id,
        },
        context: authHeader(),
      });
      setDialogOpen(true);
    } else {
      let where = { code: testCode };
      fetch(URLDATA.url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
          xcvtoken: localStorage.getItem("id"),
        },
        body: JSON.stringify({
          query: getTestDetails,
          variables: {
            where: where,
          },
          context: { clientName: "server1" },
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.errors) {
            const errText = JSON.parse(res.errors[0].message);
            throw new Error(errText.error);
          }
          const {
            data: {
              allTests: { Tests = [] },
            },
          } = res;
          if (Tests.length) {
            swal("Test Code already exist");
          } else {
            saveMutation({
              variables: {
                input: fieldsT,
              },
              context: authHeader(),
            });
            setDialogOpen(true);
          }
        })
        .catch((err) => {
          swal(err.message);
          router.push("/");
        });
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    router.push({ pathname: "/tests" });
    setFormProcessing(false);
  };
  function submitCompleted() {
    if (returnData !== undefined) {
      setCreatedTestId(returnData.saveTests.id);
    }
    setFormProcessing(true);
    setDialogOpen(true);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (testCode === "") { // eslint-disable-line
      errors.testCode = "Test code is required";
      setErrors({ ...errors });
    } else if (duration == "" || parseInt(duration) == 0) { // eslint-disable-line
      errors.duration = "Duration is required and should be greater than 0";
      setErrors({ ...errors });
    }

    let data = JSON.parse(localStorage.getItem("EditRowData"));
    if (data) {
      setCreatedTestId(data.id);
    }
    if (testCode != "" && duration != "" && parseInt(duration) > 0) { // eslint-disable-line
      makePost();
    }
  };

  const handleChange = (e, field, key) => {
    e.target.value = e.target.value < 0 ? 0 : e.target.value;
    let fieldsT = { ...fields };
    let complexityValue = fieldsT[key];
    const _value = e.target.value;
    const convertInputFieldName = {
      easy: "Easy",
      advance: "Advance",
      intermediate: "Intermediate",
      beginner: "Beginner",
    };

    const isKeyPresent =
      complexityValue.complexity[convertInputFieldName[field]];

    if (
      !isKeyPresent ||
      isKeyPresent < _value ||
      _value == 0 || // eslint-disable-line
      _value % 1 != 0 // eslint-disable-line
    ) {
      e.target.value = "";
      if (_value == 0) { // eslint-disable-line
        complexityValue.inputValues = {
          ...complexityValue.inputValues,
          [field]: e?.target?.value,
        };
        setFields({ ...fieldsT });
      }
      return true;
    } else {
      complexityValue.inputValues = {
        ...complexityValue.inputValues,
        [field]: e?.target?.value,
      };
      setFields({ ...fieldsT });
    }
  };

  const skillsHandler = (val) => {
    if (val == null) {
      setSelectSkillValue(val);
      return true;
    }

    const cloneFetchAvailableQuestions = _.cloneDeep(fetchAvailableQuestions);
    const _skillValue = val.value;
    const _data = cloneFetchAvailableQuestions.filter(
      (item) => item.skill == _skillValue // eslint-disable-line
    );
    let addUniqueType = [];
    let validateUniqueType = [];
    for (let item of _data) {
      const ele = item.type
      if (validateUniqueType.length > 0) {
        if (!validateUniqueType.includes(ele)) {
          validateUniqueType = [...validateUniqueType, ele];

          addUniqueType = [...addUniqueType, { label: ele, value: ele }];
        }
      } else {
        validateUniqueType = [ele];

        addUniqueType = [{ label: ele, value: ele }];
      }
    }

    setSkillTypes(addUniqueType);
    setSelectSkillValue(val);
  };

  const addSkillRow = () => {
    const _skillValue = selectSkillValue.value;
    const _typeValue = selectSkillTypesValue.value;
    const _skillLabel = selectSkillValue.label;
    const _typeLabel = selectSkillTypesValue.label;
    const isFieldsEmpty = Object.keys(fields).length > 0;
    const cloneFetchAvailableQuestions = _.cloneDeep(fetchAvailableQuestions);
    const _data = cloneFetchAvailableQuestions.filter(
      (item) => item.skill == _skillValue && item.type == _typeValue // eslint-disable-line
    );

    if (!!fields[`${_skillLabel}_${_typeLabel}`]) {
      swal("Already Added");
      return true;
    }

    if (_data.length == 0) { // eslint-disable-line
      swal("No Data Found");
      return true;
    }

    if (isFieldsEmpty) {
      for (const key in fields) {
        if (key != `${_skillValue}_${_typeValue}`) { // eslint-disable-line
          const _filterData = formatSkillData(_data);
          const x = {
            ...fields,
            ..._filterData,
          };
          setFields(x);
          setSelectSkillTypesValue({});
          setSelectSkillValue({});
        }
      }
    } else {
      const _filterData = formatSkillData(_data);
      setFields(_filterData);
      setSelectSkillTypesValue({});
      setSelectSkillValue({});
    }
  };

  const testCodeHandler = (e) => {
    const _errors = _.cloneDeep(errors);
    const value = e.target.value;

    if (value != "") { // eslint-disable-line
      delete _errors.testCode;
      setErrors(_errors);
    }
    setTestCode(value);
  };

  const durationHandler = (e) => {
    const _errors = _.cloneDeep(errors);
    const value = e.target.value;
    if (value > 0) {
      delete _errors.duration;
      setErrors(_errors);
      setDuration(value);
    } else {
      setDuration("");
    }
  };

  const HHMMSSTomins = (hms) => {
    let a = hms.split(":"); // split it at the colons
    return +a[0] * 60 + +a[1];
  };

  let grandTotal = 0;
  return (
    // <div className="App">
    <>
      <div className="inner-header">
        <div className="">
          <h4 className="page-title">
            {JSON.parse(localStorage.getItem("EditRowData"))
              ? "Edit"
              : "Create"}{" "}
            Test
          </h4>
        </div>
        <div>
          <Link className="navNames" to="/tests">
            <Button color="primary" variant="outlined">
              {" "}
              Back{" "}
            </Button>
          </Link>
        </div>
      </div>
      <Grid container spacing={4} xs={12} md={12} className="formSize">
        <Grid item xs={6} md={6}>
          <TextField
            className="inputWidth"
            variant="outlined"
            id="testCode"
            label="Test Code"
            type="text"
            value={testCode || ""}
            onChange={(e) => testCodeHandler(e)}
            // onBlur={(e) => setTestCode(e.target.value)} //code
            // InputLabelProps={{shrink:true}}
            InputProps={{
              readOnly: false,
            }}
            required
          />
          <div style={{ color: "red" }}>{errors["testCode"]}</div>
        </Grid>

        <Grid item xs={6} md={6}>
          <TextField
            className="inputWidth without_ampm"
            variant="outlined" // Test duration
            id="duration"
            label="duration ( in minutes )"
            type="number"
            ampm={false}
            value={duration || ""}
            placeholder="minutes"
            onChange={(e) => durationHandler(e)}
            InputProps={{
              readOnly: false,
            }}
            required
          />
          <div style={{ color: "red" }}>{errors["duration"]}</div>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            className="inputWidth"
            variant="outlined"
            id="description"
            label="Description"
            placeholder="Enter Descriptions"
            type="text"
            value={description || ""}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            InputProps={{
              readOnly: false,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Autocomplete
            disablePortal
            options={allSkills}
            onChange={(_e, val) => skillsHandler(val)}
            value={
              selectSkillValue && selectSkillValue.label
                ? selectSkillValue
                : null
            }
            renderInput={(params) => (
              <TextField variant="outlined" {...params} label="Skills" />
            )}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Autocomplete
            disablePortal
            options={skillTypes}
            onChange={(_e, val) => setSelectSkillTypesValue(val)}
            value={
              selectSkillTypesValue && selectSkillTypesValue.label
                ? selectSkillTypesValue
                : null
            }
            renderInput={(params) => (
              <TextField variant="outlined" {...params} label="Types" />
            )}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Button color="primary" variant="outlined" onClick={addSkillRow}>
            Add Row
          </Button>
        </Grid>

        {Object.entries(fields).map((item, index) => {
          let uniqueQuetionSum = 0;

          if (!!item[1].inputValues) {
            Object.values(item[1].inputValues).forEach((num) => {
              uniqueQuetionSum +=
                num === null || num === "" ? 0 : parseInt(num);
            });
            grandTotal += uniqueQuetionSum;
          }
          return (
            <Grid container item xs={12} md={12} spacing={1}>
              <Grid item xs={12} md={4}>
                <label
                  key={index}
                >{`${item[1].label} (${item[1].type})`}</label>
              </Grid>

              <Grid container item xs={12} md={8} spacing={2}>
                <Grid item xs={2}>
                  <TextField
                    className="inputWidth"
                    variant="outlined"
                    id="easy"
                    // key={index}
                    placeholder="Input No. of questions"
                    label={
                      item[1].complexity.Easy
                        ? `Easy(${item[1].complexity.Easy})`
                        : "Easy(0)"
                    }
                    type="number"
                    value={
                      item[1].inputValues ? item[1].inputValues.easy : null
                    }
                    onChange={(e) => {
                      handleChange(e, "easy", item[0]);
                    }}
                    onBlur={(e) => {
                      handleChange(e, "easy", item[0]);
                    }}
                    InputProps={{
                      readOnly: false,
                      // style: styles.input,
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    className="inputWidth"
                    variant="outlined"
                    id="beginnerQuestion"
                    // key={index}
                    placeholder="Input No. of questions"
                    // label="Beginner"
                    label={
                      item[1].complexity.Beginner
                        ? `Beginner(${item[1].complexity.Beginner})`
                        : "Beginner(0)"
                    }
                    type="number"
                    value={
                      item[1].inputValues ? item[1].inputValues.beginner : null
                    }
                    onChange={(e) => {
                      handleChange(e, "beginner", item[0]);
                    }}
                    onBlur={(e) => {
                      handleChange(e, "beginner", item[0]);
                    }}
                    InputProps={{
                      readOnly: false,
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    className="inputWidth"
                    variant="outlined"
                    id="intermediateQuestion"
                    // key={index}
                    placeholder="Input No. of questions"
                    // label="Intermediate"
                    label={
                      item[1].complexity.Intermediate
                        ? `Intermediate(${item[1].complexity.Intermediate})`
                        : "Intermediate(0)"
                    }
                    type="number"
                    // value={item[1]?.complexity?.intermediate || null}
                    value={
                      item[1].inputValues
                        ? item[1].inputValues.intermediate
                        : null
                    }
                    onChange={(e) => {
                      handleChange(e, "intermediate", item[0]);
                    }}
                    onBlur={(e) => {
                      handleChange(e, "intermediate", item[0]);
                    }}
                    // InputLabelProps={{ shrink: true }}
                    // InputLabelProps={{ style: styles.inputLabels }}
                    InputProps={{
                      readOnly: false,
                      // style: styles.input,
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    className="inputWidth"
                    variant="outlined"
                    id="advanceQuestion"
                    // key={index}
                    placeholder="Input No. of questions"
                    // label="Advance"
                    label={
                      item[1].complexity.Advance
                        ? `Advance(${item[1].complexity.Advance})`
                        : "Advance(0)"
                    }
                    type="number"
                    // value={item[1]?.complexity?.adavance || null}
                    value={
                      item[1].inputValues ? item[1].inputValues.advance : null
                    }
                    onChange={(e) => {
                      handleChange(e, "advance", item[0]);
                    }}
                    onBlur={(e) => {
                      handleChange(e, "advance", item[0]);
                    }}
                    // InputLabelProps={{ shrink: true }}
                    // InputLabelProps={{ style: styles.inputLabels }}
                    InputProps={{
                      readOnly: false,
                      // style: styles.input,
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  item
                  xs={2}
                >
                  <span> = </span>
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    className="inputWidth"
                    variant="outlined"
                    // key={index}
                    placeholder="Input No. of questions"
                    label="Total Questions"
                    type="number"
                    value={uniqueQuetionSum || 0}
                    InputLabelProps={{
                      // style: styles.inputLabels,
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                      // style: styles.input
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
        <Grid container item xs={12} md={12} spacing={1}>
          <Grid item xs={12} md={4}>
            <label>Grand total of questions</label>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={8}
            spacing={1}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              item
              xs={2}
            >
              <span> = </span>
            </Grid>

            <Grid item xs={2}>
              <TextField
                className="inputWidth"
                variant="outlined"
                // key={index}
                placeholder="Input No. of questions"
                label="Grand Total"
                type="number"
                value={grandTotal || 0}
                InputLabelProps={{
                  // style: styles.inputLabels,
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                  // style: styles.input
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} id="testsaddgrid">
          <div style={{ color: "red" }} id="testerror">{formError}</div>
          <Button
            color="primary"
            variant="contained"
            disabled={loading || formProcessing}
            onClick={(e) => {
              handleSubmit(e);
            }}
            id="button_ECDF85"
          >
            {(loading || formProcessing) && (
              <span style={{ color: "white" }} id="testspan">
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px" }}
                />{" "}
                Please Wait...
              </span>
            )}
            {!(loading || formProcessing) && (
              <span>
                {localStorage.getItem("EditRowData") ? "Update" : "Submit"}
              </span>
            )}
          </Button>
          <Dialog
            id="testdialog"
            open={dialogOpen}
            onClose={handleDialogClose}
            disableEscapeKeyDown={true}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent id="testcontext">
              <div style={{ width: "300px" }}>
                <DialogContentText id="alert-dialog-description">
                  {"Success!!"}
                </DialogContentText>
              </div>
            </DialogContent>
            <DialogActions id="testactions">
              <Button
                disabled={loadingNew}
                onClick={handleDialogClose}
                color="primary"
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </>
  );
}

export default AddTest;
