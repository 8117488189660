import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  DataGrid as DataGridElement,
  GridToolbar
} from "@material-ui/data-grid";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import "../All.css";
import URLDATA from "../config";
import {
  getUserAvailabityTimes,
  getResumeResourceData,
  DownloadFile,
} from "../query.js";
import swal from "sweetalert";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import downloadjs from "downloadjs";
import { CellModal } from "../reuseableComponents";
import { clearAllPersistedState } from "../utils/usePersistedState";
import { customNoRowsOverlay } from '../utils/overlay'
import Edit from "@material-ui/icons/Edit";
import { useHistory } from "react-router";

const styles = () => ({});
const DataGrid = () => {
  const [columns, setColumns] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [isActive, setIsActive] = useState(0);
  const [contractorActive, setContractorActive] = useState(0);
  const [searchFields, setSearchFields] = React.useState({
    projectEndDate: "",
    skill: "",
    otherSkill: "",
  });
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(100);
  const [searchFieldsT, setSearchFieldsT] = React.useState({
    projectEndDate: "",
    skill: "",
    otherSkill: "",
  });
  const [loading, setLoading] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [popupData, setPopupData] = useState(false);
  const [filters, setFilters] = useState({});
  const [prevPage, setPrevPage] = useState(0);

  useEffect(() => {
    clearAllPersistedState();
    setColumns(gridColumns);
    fetchSkills();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoading(true);
    setRows([]);
    setTotalCount(0);
    fetchData();
  }, [searchFields, contractorActive, isActive]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filters.value !== undefined && filters.value !== "") {
      setPrevPage(page);
      setPage(0);
    } else if (prevPage !== 0) setPage(prevPage);
  }, [filters]);// eslint-disable-line react-hooks/exhaustive-deps

  let router = useHistory();

  let gridColumns = [
    {
      field: "notes",
      headerName: "User Notes",
      width: 195,
      hide: false,
      renderCell: (params) => <p>{params.row.notes}</p>,
    },
    {
      field: "name",
      headerName: "Name",
      width: 195,
      hide: false,
      renderCell: (params) => <p>{params.row.name}</p>,
    },

    {
      field: "designationName",
      headerName: "Designation",
      width: 199,
      hide: false,
      renderCell: (params) => <CellModal data={params} handler={showAllData} />,
    },
    {
      field: "primarySkillName",
      headerName: "Primary Skill",
      width: 159,
      hide: false,
      renderCell: (params) => <CellModal data={params} handler={showAllData} />,
    },
    {
      field: "secondarySkillName",
      headerName: "Secondary Skill",
      width: 179,
      hide: false,
      renderCell: (params) => <CellModal data={params} handler={showAllData} />,
    },
    {
      field: "secondarySkill2Name",
      headerName: "Third Skill",
      width: 149,
      hide: false,
      renderCell: (params) => <CellModal data={params} handler={showAllData} />,
    },
    {
      field: "otherSkillName",
      headerName: "Other Skills",
      width: 155,
      hide: false,
      renderCell: (params) => <CellModal data={params} handler={showAllData} />,
    },
    {
      headerName: "Availability",
      field: "allocation",
      width: 145,
      hide: false,
    },
    {
      headerName: "User Availability Date",
      field: "userAvailableLatestDate",
      width: 220,
      hide: false,
    },
    { headerName: "Cost", field: "hourlyCost", width: 115, hide: false },
    {
      headerName: "Current Project",
      field: "currProject",
      width: 250,
      hide: false,
      renderCell: (params) => <CellModal data={params} handler={showAllData} />,
    },
    { headerName: "Total Exp", field: "totalExpNew", width: 135, hide: false },
    {
      headerName: "Resume",
      field: "resume",
      width: 135,
      hide: false,
      renderCell: (params) => {
        let documentTypeArray = [];
        if (params.row.docType !== null) {
          documentTypeArray = params.row.docType.split(",");
        }
        let resume =
          documentTypeArray &&
          documentTypeArray.length > 0 &&
          documentTypeArray.some((type) => {
            return type.toLowerCase() === "resume";
          });
        return resume && params.row.docPath ? (
          <div>
            <Button
              style={{ margin: "6px" }}
              color="primary"
              size="small"
              variant="contained"
              onClick={() => handleDownLoadResume(params, "Resume")}
            >
              Download
            </Button>
          </div>
        ) : null;
      },
    },
    {
      headerName: "Qss-Resume",
      field: "qss-resume",
      width: 180,
      hide: false,
      renderCell: (params) => {
        let documentTypeArray = [];
        if (params.row.docType !== null) {
          documentTypeArray = params.row.docType.split(",");
        }
        let qssResume =
          documentTypeArray &&
          documentTypeArray.length > 0 &&
          documentTypeArray.some((type) => {
            return type.toLowerCase() === "qss-resume";
          });
        return qssResume && params.row.docPath ? (
          <div>
            <Button
              style={{ margin: "6px" }}
              color="primary"
              size="small"
              variant="contained"
              onClick={() => handleDownLoadResume(params, "Qss-Resume")}
            >
              Download
            </Button>
          </div>
        ) : null;
      },
    },
    // {
    //   headerName: "Triumfo-Resume",
    //   field: "TIS-Resume",
    //   width: 200,
    //   hide: false,
    //   renderCell: (params) => {
    //     let documentTypeArray = [];
    //     if (params.row.docType !== null) {
    //       documentTypeArray = params.row.docType.split(",");
    //     }
    //     let tisResume =
    //       documentTypeArray &&
    //       documentTypeArray.length > 0 &&
    //       documentTypeArray.some((type) => {
    //         return type.toLowerCase() === "tis-resume";
    //       });
    //     return tisResume && params.row.docPath ? (
    //       <div>
    //         <Button
    //           style={{ margin: "6px" }}
    //           color="primary"
    //           size="small"
    //           variant="contained"
    //           onClick={() => handleDownLoadResume(params, "TIS-Resume")}
    //         >
    //           Download
    //         </Button>
    //       </div>
    //     ) : null;
    //   },
    // },
    {
      disableExport: true,
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        // added hover effect for edit icon //
        <strong title="edit">
          <Edit
            onClick={() => {
              localStorage.setItem("EditRowData", JSON.stringify(params.row));
              router.push({ pathname: "/edit-userAvailability" });
            }}
            style={{ marginInline: "4px", cursor: "pointer" }}
          />
        </strong>
      ),
    },
  ];

  const handleSearchItemChange = (
    e = { target: { value: "" } },
    fieldName = []
  ) => {
    let fieldsT = searchFieldsT;
    fieldsT[fieldName] = e && e.target ? e.target.value : e;
    setSearchFieldsT({ ...searchFieldsT });
  };

  const fetchSkills = () => {
    fetch(URLDATA.url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
        xcvtoken: localStorage.getItem("id"),
      },
      body: JSON.stringify({
        query: `
        query {
          allSkills{
            Skills {
              id
              name
            }
          }
        }`,
      }),
    })
      .then((res) => {
        if (res.status === 500 || res.status === 400) {
          return res.text().then((text) => {
            const errorText = JSON.parse(text).error.message;
            throw new Error(errorText);
          });
        } else return res.json();
      })
      .then((res) => {
        setApiData(res.data.allSkills.Skills);
      })
      .catch((err) => {
        console.log(err.message);
        swal(err.message);
      });
  };

  const getWhereFromSearchFields = () => {
    let localStorageData = localStorage.getItem("skills");
    searchFields.skill = localStorageData;

    return JSON.parse(
      "{" +
      Object.entries(searchFields)
        .filter((item) => {
          if (typeof item[1] == "string") {
            return item[1] ? true : false;
          } else if (item[1] instanceof Array) {
            return item[1] && item[1].length > 0 ? true : false;
          } else {
            return item[1] !== null && typeof (item[1] !== "undefined")
              ? true
              : false;
          }
        })
        .map((item) => {
          if (typeof item[1] == "string")
            return '"' + item[0] + '"' + ":" + '"' + item[1] + '"'; // eslint-disable-line
          else if (item[1] instanceof Array) {
            return (
              '{"' + item[0] + '":{"inq":' + JSON.stringify(item[1]) + "}}"
            );
          } else {
            if (typeof item[1] == "number") {
              return '"' + item[0] + '"' + ":" + '"' + item[1] + '"'; // eslint-disable-line
            } else if (
              typeof item[1] == "object" &&
              item[1].hasOwnProperty("type") &&
              item[1].type === "DatePicker"
            ) {
              let Dates = {
                between: [
                  item[1].value + "T00:00:00.000Z",
                  item[1].value + "T23:59:00.000Z",
                ],
              };
              return '{"' + item[0] + '":' + JSON.stringify(Dates) + "}";
            } else
              return '{"' + item[0] + '":' + JSON.stringify(item[1]) + "}";
          }
        })
        .join(",") +
      "}"
    );
  };

  const fetchData = () => {
    fetch(URLDATA.url1, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: getUserAvailabityTimes,
        variables: {
          isActive: isActive,
          where: getWhereFromSearchFields(),
          showAllUser: isActive,
          showContractorUser: contractorActive,
        },
        context: { clientName: "server2" },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        let newData = data.data?.getUserAvailabityTime;
        newData = newData.map((item) => {
          let newItem = { ...item };
          newItem.allocation = 100 - newItem.allocation;
          let psr =
            newItem.primarySkillRating == null ? 0 : newItem.primarySkillRating;
          let ssr =
            newItem.secondarySkillRating == null
              ? 0
              : newItem.secondarySkillRating;
          let ssr2 =
            newItem.secondarySkill2Rating == null
              ? 0
              : newItem.secondarySkill2Rating;
          newItem.primarySkillName =
            newItem.primarySkillName === "N/A"
              ? ""
              : newItem.primarySkillName + " " + "(" + psr + ")"; // eslint-disable-line
          newItem.secondarySkillName =
            newItem.secondarySkillName === "N/A"
              ? ""
              : newItem.secondarySkillName + " " + "(" + ssr + ")"; // eslint-disable-line
          newItem.secondarySkill2Name =
            newItem.secondarySkill2Name === "N/A"
              ? ""
              : newItem.secondarySkill2Name + " " + "(" + ssr2 + ")"; // eslint-disable-line
          newItem.otherSkillName =
            newItem.otherSkillName == null ? "" : newItem.otherSkillName;
          newItem.totalExpNew =
            newItem.totalExpNew == null ? "" : newItem.totalExpNew + " (yrs)";
          newItem.hourlyCost =
            newItem.hourlyCost === 0 ? "" : "$ " + newItem.hourlyCost + " /hr";
          return newItem;
        });
        setRows(newData);
        setTotalCount(newData && newData.length);
        setLoading(false);
      })
      .catch((er) => {
        console.log(er, "err");
      });
  };

  useEffect(() => { setPage(0) }, [pageSize])

  const handleChangeCheckBox = (event) => {
    setPage(0)
    setContractorActive(0);
    setIsActive(event.target.checked ? 1 : 0);
  };

  const handleChangeContractorCheckBox = (event) => {
    setPage(0)
    setIsActive(0);
    setContractorActive(event.target.checked ? 1 : 0);
  };
  const handlePageChange = (param) => {
    setPage(param);
  };
  const handlePageSizeChange = async (params) => {
    setPageSize(params);
  };
  const doSearch = () => {
    setSearchFields({
      ...searchFieldsT,
    });

    if (searchFieldsT.skill !== null)
      localStorage.setItem("skills", searchFieldsT.skill);
    else localStorage.setItem("skills", "");
  };

  const doClear = () => {
    localStorage.removeItem("skills");
    setSearchFieldsT({
      projectEndDate: null,
      skill: "",
      otherSkill: "",
    });
    setSearchFields({ projectEndDate: null });
  };
  const filter = createFilterOptions();

  const showAllData = (data) => {
    const { value } = data;
    popupHandler(true, value);
  };

  const popupHandler = (isOpens, data) => {
    setIsOpen(isOpens);
    setPopupData(data);
  };
  const handleDownLoadResume = async (params, typeOfResume) => {
    const data = await getUserDocument(params.id);
    let newData = [];
    const newwdata = data.data?.queryUserAllFiles;
    const showPop = newwdata && newwdata.length > 0 ? true : false;
    if (showPop) {
      if (typeOfResume === "Resume") {
        newData = data.data?.queryUserAllFiles.filter(
          (ele) => ele.docType.toLowerCase() === "resume"
        );
        if (newData && newData.length > 1) {
          let filteredData = newData[0];
          newData.map((ele) => { // eslint-disable-line
            if (
              new Date(ele.updatedAt).getTime() >
              new Date(filteredData.updatedAt).getTime()
            ) {
              filteredData = ele;
            }
          });
          newData = [filteredData];
        }
      }
      if (typeOfResume === "Qss-Resume") {
        newData = data.data?.queryUserAllFiles.filter(
          (ele) => ele.docType.toLowerCase() === "qss-resume"
        );
        if (newData && newData.length > 1) {
          let filteredData1 = newData[0];
          newData.map((ele) => { // eslint-disable-line
            if (
              new Date(ele.updatedAt).getTime() >
              new Date(filteredData1.updatedAt).getTime()
            ) {
              filteredData1 = ele;
            }
          });
          newData = [filteredData1];
        }
      }
      if (typeOfResume === "TIS-Resume") {
        newData = data.data?.queryUserAllFiles.filter(
          (ele) => ele.docType.toLowerCase() === "tis-resume"
        );
        if (newData && newData.length > 1) {
          let filteredData2 = newData[0];
          newData.map((ele) => { // eslint-disable-line
            if (
              new Date(ele.updatedAt).getTime() >
              new Date(filteredData2.updatedAt).getTime()
            ) {
              filteredData2 = ele;
            }
          });
          newData = [filteredData2];
        }
      }
    }
    if (
      showPop &&
      newData &&
      newData.length > 0 &&
      localStorage.getItem("id")
    ) {
      await downloadResumeFile(params.id, newData[0].id, newData[0].docPath);
    } else {
      swal("Does not Exist", {
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
  };

  const downloadResumeFile = async (userid, docId, path) => {
    return fetch(URLDATA.url1, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: DownloadFile,
        variables: {
          id: userid,
          path: path,
          flag: "User",
          docId: docId,
          adminId: localStorage.getItem("id"),
        },
        context: { clientName: "server2" },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.errors) {
          swal(res.errors[0].message, {
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else {
          let queryFill = DownloadFile.substring(DownloadFile.indexOf("{") + 1)
            .split("(")[0]
            .trim();
          downloadjs(
            Buffer.from(res.data[`${queryFill}`].fileData, 'base64').toString("binary"),
            res.data[`${queryFill}`].fileName,
            res.data[`${queryFill}`].fileType
          );
        }
      });
  };

  const getUserDocument = async (userId) => {
    return fetch(URLDATA.url1, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: getResumeResourceData,
        variables: {
          id: userId,
        },
        context: { clientName: "server2" },
      }),
    }).then((res) => res.json());
  };

  const handleautocomplete = (_event, newValue) => {
    if (typeof newValue === "object") {
      setSearchFieldsT({
        ...searchFieldsT,
        skill: newValue && newValue.name,
      });
    } else if (newValue && newValue.inputValue) {
      setSearchFieldsT({
        ...searchFieldsT,
        skill: newValue.inputValue,
      });
    } else {
      setSearchFieldsT({
        ...searchFieldsT,
        newValue,
      });
    }
  };
  return (
    <Grid container xs={12} style={{ height: "463px" }}>
      <Dialog onClose={() => popupHandler(false, "")} open={isOpen}>
        <DialogContent>{popupData}</DialogContent>
      </Dialog>
      <Grid container xs={12}>
        <div className="inner-header">
          <div>
            <h4 className="page-title">User Availability Report</h4>
          </div>
          <div></div>
        </div>
      </Grid>
      <Grid
        xs={12}
        style={{ display: "flex", flexWrap: "wrap", marginTop: "30px" }}
      >
        <FormControl style={{ marginBottom: "5px" }} sx={{ minWidth: "100%" }}>
          <InputLabel id="demo-dropdown-label">Availability</InputLabel>
          <Select
            style={{ width: "198px" }}
            labelId="demo-dropdown"
            id="dropdown"
            value={searchFieldsT && searchFieldsT.projectEndDate}
            name="role"
            onChange={(e) => {
              handleSearchItemChange(e, ["projectEndDate"]);
            }}
            input={<Input />}
          >
            {[
              {
                value: 1,
                text: "Availability today",
                key: "dropdown_option_E60BBC34-BEFA-4158-B54C-9C62A179CEE8",
              },
              {
                value: 6,
                text: "Availability in 7 days",
                key: "dropdown_option_E1E50ED0-9663-4EB3-8B61-3C12561A94C3",
              },
              {
                value: 14,
                text: "Availability in 15 days",
                key: "dropdown_option_4B042A3B-F516-4547-B282-4C8169BCB46B",
              },
              {
                value: 29,
                text: "Availability in 1 month",
                key: "7E0534A7-2B5F-477D-8F66-AC518F36367D",
              },
              {
                value: 44,
                text: "Availability in 1.5 month",
                key: "7E0534A7-2B5F-477D-8F66-AC6F6GF36367D",
              },
              {
                value: 59,
                text: " Availability in 2 months",
                key: "6746A31F-E0A2-435A-A524-F146F45D4E2B",
              },
            ].map((obj) => {
              return (
                <MenuItem key={obj.key} value={obj.value}>
                  {obj.text}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl
          style={{ marginBottom: "5px", marginLeft: "5px", marginTop: "4px" }}
          sx={{ minWidth: "100%" }}
        >
          <Autocomplete
            style={{ width: "198px" }}
            value={
              !localStorage.getItem("skill")
                ? localStorage.getItem("skills")
                : searchFieldsT && searchFieldsT.skill
            }
            onChange={handleautocomplete}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              const isExisting = options.some(
                (option) => inputValue === option.name
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  inputValue,
                  name: `${inputValue}`,
                });
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={apiData.map((option) => option)}
            getOptionLabel={(item) => {
              if (typeof item === "string") {
                return item;
              }
              if (item.inputValue) {
                return item.inputValue;
              }
              return item.name;
            }}
            renderOption={(props, option2) => (
              <li {...props}>{option2.name}</li>
            )}
            sx={{ width: 300 }}
            freeSolo
            renderInput={(params) => <TextField {...params} label="Skills" />}
          />
        </FormControl>

        <Grid item xs={4}>
          <Button
            style={{ margin: "6px" }}
            color="primary"
            variant="contained"
            disabled={loading}
            onClick={doSearch}
            id="gridSearch"
          >
            {loading && (
              <span style={{ color: "white" }}>
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px" }}
                />{" "}
                Search
              </span>
            )}
            {!loading && <span>Search</span>}
          </Button>
          <Button
            style={{ margin: "6px", width: "100px" }}
            color="default"
            variant="contained"
            disabled={loading}
            onClick={doClear}
            id="gridClear"
          >
            {loading && (
              <span style={{ color: "white" }}>
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px" }}
                />{" "}
                Clear
              </span>
            )}
            {!loading && <span>Clear</span>}
          </Button>
        </Grid>
        <FormControlLabel
          control={
            <Checkbox
              checked={contractorActive === 1 ? true : false}
              color="primary"
              onChange={handleChangeContractorCheckBox}
            />
          }
          label="Show Contractor"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isActive === 1 ? true : false}
              color="primary"
              onChange={handleChangeCheckBox}
            />
          }
          label="Show all users"
        />
      </Grid>
      <Grid className="grid_container_dataGrid" container>
        <Grid
          className="grid_Data_Grid"
          item
          xs={12}
          md={12}
          style={{ height: "80vh" }}
        >
          <DataGridElement
            className="insideDataGrid"
            columns={columns}
            rows={rows}
            rowCount={totalCount}
            rowsPerPageOptions={[10, 15, 20, 25, 50, 75, 100]}
            pageSize={pageSize}
            page={page}
            density="compact"
            onFilterModelChange={(model) => setFilters(model?.items[0])}
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: customNoRowsOverlay,
            }}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            componentsProps={{
              cell: {
                onMouseEnter: () => {
                  console.log("HIT");
                },
                onMouseLeave: () => {
                  console.log("HIT");
                },
              },
            }}
            loading={loading}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const UserReportsInner = () => {
  return (
    <form style={{ marginTop: "10px" }}>
      <div style={{ margin: "0% 0% 0% 2.2%", height: "550px" }}>
        <Grid container spacing={4} xs={12} md={12}>
          <DataGrid />
        </Grid>
      </div>
    </form>
  );
};
UserReportsInner.propTypes = {
  classes: PropTypes.object.isRequired,
};
const CompWithStyleInner = withStyles(styles, { withTheme: true })(
  UserReportsInner
);
function userReport() {
  return <CompWithStyleInner />;
}
export default userReport;
